import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-center items-center mt-5">
      <div className="text-center">
        <h1 className="text-2xl font-semibold mb-5">Oops</h1>
        <p>We can't find this page.</p>
        <button
          className="mt-4 px-4 py-2 bg-teal-500 text-white rounded-md hover:font-semibold hover:bg-teal-600"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Go Back
        </button>
      </div>
    </div>
  );
};

export default NotFound;
