import React, { useState } from "react";
import { GoogleMap, Circle } from "@react-google-maps/api";
import GoogleMapLoader from "../GoogleMapLoader";

const containerStyle = {
  width: "100%",
  height: "200px",
};

const GoogleMapComponent = ({ mapCenter }) => {
  const [map, setMap] = useState(null);
  const { lat, lng } = mapCenter;

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <GoogleMapLoader>
      <div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat, lng }}
          zoom={14}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }}
        >
          {/* Adding a Circle marker */}
          <Circle
            center={{ lat, lng }}
            radius={300}
            options={{
              strokeColor: "#eab308",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#fde047",
              fillOpacity: 0.35,
            }}
          />
        </GoogleMap>
      </div>
    </GoogleMapLoader>
  );
};

export default GoogleMapComponent;
