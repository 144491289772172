import React, { useEffect, useState } from "react";
import Select from "react-select";
import categories from "../config/categories"; // Adjust the import path as necessary

function CategorySelector({
  onCategorySelected,
  onSubcategorySelected,
  initialCategory,
  initialSubcategory,
}) {
  const [mainCategory, setMainCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  useEffect(() => {
    if (initialCategory) {
      const mainCat = {
        value: initialCategory,
        label: initialCategory,
      };
      setMainCategory(mainCat);
    }
    if (initialSubcategory) {
      const subCat = {
        value: initialSubcategory,
        label: initialSubcategory,
      };
      setSubCategory(subCat);
    }
  }, [initialCategory, initialSubcategory]);

  const handleMainCategoryChange = (selectedOption) => {
    setMainCategory(selectedOption);
    onCategorySelected(selectedOption); // Notify parent component
    setSubCategory(null); // Reset subCategory state
  };

  const handleSubCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
    onSubcategorySelected(selectedOption); // Notify parent component
  };

  // Main categories options
  const mainCategoriesOptions = categories.map((category) => ({
    value: category.name,
    label: category.name,
  }));

  // Get the selected main category object
  const selectedMainCategory = categories.find(
    (category) => category.name === mainCategory?.value
  );

  // Subcategories options
  const subCategoriesOptions = selectedMainCategory?.subcategories
    ? selectedMainCategory.subcategories.map((subcat) => ({
        value: subcat.name,
        label: subcat.name,
      }))
    : [];

  return (
    <div>
      <h2 className="label">Category</h2>
      <div className="flex flex-col gap-2 text-sm">
        {/* Main Category Selector */}
        <Select
          value={mainCategory}
          onChange={handleMainCategoryChange}
          options={mainCategoriesOptions}
          isSearchable
          placeholder="Select a Category"
        />

        {/* Subcategory Selector */}
        {subCategoriesOptions.length > 0 && (
          <Select
            value={subCategory}
            onChange={handleSubCategoryChange}
            options={subCategoriesOptions}
            isSearchable
            placeholder="Select a Subcategory"
          />
        )}
      </div>
    </div>
  );
}

export default CategorySelector;
