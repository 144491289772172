import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faInfoCircle,
  faShieldAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/Footer";
import { SyncLoader } from "react-spinners";
import CategorySelector from "../components/CategorySelector";

const EditListing = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  const [imageURLs, setImageURLs] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dailyRate: "",
    threeDayCost: "",
    sevenDayCost: "",
    deposit: "",
    quantity: "",
    minRentDays: "",
    location: "",
    status: "",
  });

  const {
    title,
    description,
    dailyRate,
    threeDayCost,
    sevenDayCost,
    deposit,
    status,
  } = formData;

  const { currentUser } = useAuth();
  const { listingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      fetchListingData();
    } else {
      toast.error("You need to be logged in to edit a listing.", {
        autoClose: 5000,
      });
      navigate("/");
    }
  }, [currentUser, listingId, navigate]);

  const fetchListingData = async () => {
    setShowSpinner(true);
    try {
      const docRef = doc(db, "listings", listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Check if the current user is the lender of the listing
        if (currentUser.uid !== data.lenderUid) {
          toast.error("You need to be the lender of this listing to edit.", {
            autoClose: 5000,
          });
          navigate("/");
          return;
        }

        // Convert amounts from cents to euros for form display
        setFormData({
          title: data.title,
          description: data.description,
          dailyRate: (data.dailyRateCents / 100).toFixed(2),
          threeDayCost: data.threeDayCostCents
            ? (data.threeDayCostCents / 100).toFixed(2)
            : "",
          sevenDayCost: data.sevenDayCostCents
            ? (data.sevenDayCostCents / 100).toFixed(2)
            : "",
          deposit: data.depositCents
            ? (data.depositCents / 100).toFixed(2)
            : "",
          quantity: data.quantity || "",
          minRentDays: data.minRentDays || "",
          location: data.location || "",
          status: data.status || "",
          category: data.category || "",
          subcategory: data.subcategory || "",
        });

        setImageURLs(data.imageURLs || []);

        // Set selected category and subcategory
        setSelectedCategory({ value: data.category, label: data.category });
        if (data.subcategory) {
          setSelectedSubcategory({
            value: data.subcategory,
            label: data.subcategory,
          });
        }
      } else {
        toast.error("Listing not found");
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to fetch listing data: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const loadCloudinaryScript = () => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        console.log("Cloudinary script loaded");
      };
    }
  };

  useEffect(() => {
    loadCloudinaryScript();
  }, []);

  const openUploadWidget = () => {
    setShowSpinner(true);
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: "prodcloudinary",
          uploadPreset: "kitShareUploadPreset",
          multiple: true,
          maxFiles: 5,
          sources: ["local", "camera"],
          folder: "listingImages",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "image",
        },
        (error, result) => {
          setShowSpinner(false);
          if (!error && result && result.event === "success") {
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]);
            console.log("Image uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload image. Please try again.");
          }
        }
      )
      .open();
  };

  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setFormData((prev) => ({ ...prev, category: category.value }));
  };

  const handleSubcategorySelected = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setFormData((prev) => ({ ...prev, subcategory: subcategory.value }));
  };

  const handleRemoveImage = (url) => {
    setImageURLs((prev) => prev.filter((image) => image !== url));
  };

  const ratesAreCorrect = (
    dailyRateCents,
    threeDayCostCents,
    sevenDayCostCents
  ) => {
    const daily = dailyRateCents;
    const threeDay = threeDayCostCents;
    const sevenDay = sevenDayCostCents;

    if (isNaN(daily)) {
      return false;
    }

    if (threeDay && sevenDay) {
      return (
        threeDay <= daily * 3 && sevenDay <= daily * 7 && threeDay <= sevenDay
      );
    } else if (threeDay) {
      return threeDay <= daily * 3;
    } else if (sevenDay) {
      return sevenDay <= daily * 7;
    } else {
      // Only dailyRate is provided
      return true;
    }
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    try {
      // Convert to cents
      const dailyRateCents = Math.round(parseFloat(dailyRate) * 100);
      const threeDayCostCents = threeDayCost
        ? Math.round(parseFloat(threeDayCost) * 100)
        : null;
      const sevenDayCostCents = sevenDayCost
        ? Math.round(parseFloat(sevenDayCost) * 100)
        : null;
      const depositCents = deposit
        ? Math.round(parseFloat(deposit) * 100)
        : null;

      // Validate rates
      if (
        !ratesAreCorrect(dailyRateCents, threeDayCostCents, sevenDayCostCents)
      ) {
        toast.error(
          "Something looks wrong with your rates. Your 3 day price should be larger than the daily rate. The 7 day price should be larger than the 3 day price.",
          { autoClose: 5000 }
        );
        setShowSpinner(false);
        return;
      }

      // Check if user has entered 0 for deposit - inform them to remove if so
      if (depositCents === 0) {
        toast.info(
          "Please remove 0 from optional deposit, leave this blank instead.",
          {
            autoClose: 5000,
          }
        );
        setShowSpinner(false);
        return;
      }

      // Build the updated data object
      const updatedData = {
        title,
        description,
        dailyRateCents,
        imageURLs,
        category: selectedCategory ? selectedCategory.value : formData.category,
        subcategory: selectedSubcategory
          ? selectedSubcategory.value
          : formData.subcategory,
        status,
      };

      // Handle deposit field
      if (depositCents && depositCents > 0) {
        updatedData.depositCents = depositCents;
      } else if (deposit === "" || deposit === null || isNaN(depositCents)) {
        // User has removed the deposit; delete the field from Firestore
        updatedData.depositCents = deleteField();
      }

      if (threeDayCostCents) {
        updatedData.threeDayCostCents = threeDayCostCents;
      }

      if (sevenDayCostCents) {
        updatedData.sevenDayCostCents = sevenDayCostCents;
      }

      const listingRef = doc(db, "listings", listingId);
      await updateDoc(listingRef, updatedData);

      toast.success("Listing updated successfully!");
      navigate(`/listings/${listingId}`);
    } catch (error) {
      console.error("Failed to update listing: ", error);
      toast.error("Error updating listing: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const changeListingStatus = async () => {
    setShowSpinner(true);
    try {
      const listingRef = doc(db, "listings", listingId);
      await updateDoc(listingRef, {
        status: status === "active" ? "inactive" : "active",
      });
      toast.success(
        `You have updated the status of your listing to ${
          status === "active" ? "inactive" : "active"
        }`
      );
      setFormData((prev) => ({
        ...prev,
        status: status === "active" ? "inactive" : "active",
      }));
    } catch (error) {
      toast.error("Failed to change status: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number") {
      if (value !== "") {
        value = parseFloat(value);
        if (isNaN(value)) value = "";
      } else {
        value = "";
      }
    }

    // Inform the user to remove deposit if they set it as 0
    if (e.target.id === "deposit" && value === 0) {
      toast.info("Leave this blank if no deposit is required.");
    }

    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        <div className="flex w-full justify-center">
          <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
            Edit Listing
          </h1>
        </div>

        <div className="flex justify-center bg-gray-50">
          <form
            className="w-11/12 md:w-2/3 xl:w-4/12"
            onSubmit={handleSaveChanges}
          >
            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Listing Title</label>
              <input
                className="shadow-md outline-none focus:border-emerald-500"
                type="text"
                id="title"
                value={title}
                onChange={onChange}
                placeholder=""
                required
              />
            </div>

            <CategorySelector
              onCategorySelected={handleCategorySelected}
              onSubcategorySelected={handleSubcategorySelected}
              initialCategory={formData.category}
              initialSubcategory={formData.subcategory}
            />

            <div className="flex flex-col w-full py-2 mb-2 mt-3">
              <label className="label">Listing Description</label>
              <textarea
                placeholder="Enter listing details"
                value={description}
                id="description"
                onChange={onChange}
                className="border-2 p-2 shadow-md outline-none focus:border-emerald-500"
                required
                rows={10}
              ></textarea>
            </div>

            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Rental price (€):</label>
              <div className="flex flex-col sm:flex-row items-center sm:justify-between gap-2">
                <div className="w-full sm:w-1/3">
                  <label className="label">Daily Rate</label>
                  <input
                    className="shadow-md outline-none focus:border-emerald-500"
                    type="number"
                    min={1}
                    required
                    step="0.01"
                    id="dailyRate"
                    value={dailyRate}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full sm:w-1/3">
                  <label className="label">Price for 3 days</label>
                  <input
                    className="shadow-md outline-none focus:border-emerald-500"
                    type="number"
                    step="0.01"
                    id="threeDayCost"
                    placeholder="Optional"
                    value={threeDayCost}
                    onChange={onChange}
                  />
                </div>
                <div className="w-full sm:w-1/3">
                  <label className="label">Price for 7 days</label>
                  <input
                    className="shadow-md outline-none focus:border-emerald-500"
                    placeholder="Optional"
                    type="number"
                    id="sevenDayCost"
                    step="0.01"
                    value={sevenDayCost}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            {/* Deposit Section */}
            <div className="w-full my-4">
              <div className="bg-white rounded-lg shadow-md p-4 mb-1">
                <label className="text-lg font-semibold mb-4 block">
                  Deposit required?
                </label>
                <div className="flex flex-col gap-4">
                  {/* Deposit Information */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      You can request a deposit for your item to help ensure its
                      safe return.
                    </p>
                  </div>
                  {/* Insurance Opportunity */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faShieldAlt}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      You'll also have the opportunity to{" "}
                      <span
                        className="text-teal-600 font-semibold hover:underline hover:text-teal-800 cursor-pointer"
                        onClick={() => navigate("/insurance")}
                      >
                        insure your item
                      </span>{" "}
                      through KitShare for each rental.
                    </p>
                  </div>
                  {/* Terms & Conditions */}
                  <div className="flex items-start gap-3">
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="text-teal-500 mt-1"
                    />
                    <p className="text-sm text-gray-700">
                      For more details, please review our{" "}
                      <span
                        className="text-teal-600 font-semibold hover:underline hover:text-teal-800 cursor-pointer"
                        onClick={() => navigate("/terms-and-conditions")}
                      >
                        Terms & Conditions
                      </span>
                      .
                    </p>
                  </div>
                </div>
              </div>

              <input
                className="shadow-md outline-none focus:border-emerald-500"
                type="number"
                placeholder="Optional Deposit"
                min={1}
                step="0.01"
                id="deposit"
                value={deposit}
                onChange={onChange}
              />
            </div>

            {/* Images Section */}
            <div className="flex flex-col items-center py-4">
              {/* Display image thumbnails */}
              {imageURLs.length > 0 && (
                <div className="flex flex-wrap gap-2 mb-4">
                  {imageURLs.map((url, index) => (
                    <div key={index} className="relative">
                      <img
                        src={url}
                        alt="Uploaded"
                        className="w-20 h-20 object-cover rounded-md shadow-md"
                      />
                      <button
                        type="button"
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 hover:bg-red-700"
                        onClick={() => handleRemoveImage(url)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="flex justify-center mt-4 mb-2 gap-3">
              {imageURLs.length < 5 && (
                <button
                  className="btn-edit px-4"
                  onClick={openUploadWidget}
                  type="button"
                  aria-label="Upload Images"
                >
                  <FontAwesomeIcon icon={faUpload} /> Upload Images (max 5)
                </button>
              )}

              <button
                type="submit"
                className={`px-7 ${
                  !title || !description || !dailyRate || imageURLs.length === 0
                    ? "btn-secondary"
                    : "btn-continue"
                }`}
                aria-label="Save Changes"
                disabled={
                  showSpinner ||
                  !title ||
                  !description ||
                  !dailyRate ||
                  imageURLs.length === 0
                }
              >
                {showSpinner ? (
                  <SyncLoader size={10} margin={2} color="white" />
                ) : (
                  <span className="text-base">Save Changes</span>
                )}
              </button>
            </div>

            {/* Change Listing Status Button */}
            <div className="flex justify-center mt-4">
              <button
                type="button"
                onClick={changeListingStatus}
                className={`px-4 ${
                  status === "active" ? "btn-delete" : "btn-primary"
                }`}
              >
                {showSpinner ? (
                  <SyncLoader size={10} margin={5} />
                ) : (
                  <span className="text-base">
                    Make {status === "active" ? "Inactive" : "Active"}
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditListing;
