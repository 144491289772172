// utils/costCalculations.js

export const calculateTotalCostCents = (numberOfDays, listing) => {
  const { dailyRateCents, threeDayCostCents, sevenDayCostCents } = listing;

  let totalCostCents = 0;

  if (numberOfDays === 1 || numberOfDays === 2) {
    totalCostCents = dailyRateCents * numberOfDays;
  } else if (numberOfDays === 3) {
    totalCostCents = threeDayCostCents ? threeDayCostCents : dailyRateCents * 3;
  } else if (numberOfDays === 7) {
    if (sevenDayCostCents) {
      totalCostCents = sevenDayCostCents;
    } else if (threeDayCostCents) {
      const averageThreeDayRate = Math.round(threeDayCostCents / 3);
      totalCostCents = averageThreeDayRate * 7;
    } else {
      totalCostCents = dailyRateCents * 7;
    }
  } else if (numberOfDays > 7) {
    if (sevenDayCostCents) {
      const averageSevenDayRate = Math.round(sevenDayCostCents / 7);
      totalCostCents =
        sevenDayCostCents + averageSevenDayRate * (numberOfDays - 7);
    } else if (threeDayCostCents) {
      const averageThreeDayRate = Math.round(threeDayCostCents / 3);
      totalCostCents = averageThreeDayRate * numberOfDays;
    } else {
      totalCostCents = dailyRateCents * numberOfDays;
    }
  } else if (numberOfDays > 3 && numberOfDays < 7) {
    if (threeDayCostCents) {
      const averageThreeDayRate = Math.round(threeDayCostCents / 3);
      totalCostCents = averageThreeDayRate * numberOfDays;
    } else {
      totalCostCents = dailyRateCents * numberOfDays;
    }
  }

  return totalCostCents;
};

export const calculateAverageRateCents = (numberOfDays, listing) => {
  const { dailyRateCents, threeDayCostCents, sevenDayCostCents } = listing;

  if (numberOfDays >= 7 && sevenDayCostCents) {
    return Math.round(sevenDayCostCents / 7);
  } else if (numberOfDays >= 3 && threeDayCostCents) {
    return Math.round(threeDayCostCents / 3);
  } else {
    return dailyRateCents;
  }
};
