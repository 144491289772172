import React, { useEffect, useState } from "react";
import { db } from "../../firebase.config";
import { collection, getDocs } from "firebase/firestore";
import { useAuth } from "../../hooks/useAuth";
import profilePicturePlaceholder from "../../assets/profilePicture.png";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import StarRating from "../StarRating";

const ListingItemLenderReviews = ({ reviews }) => {
  //const [reviews, setReviews] = useState([])

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "dd-MM-yyyy");
  };

  return (
    <div>
      <div className="">
        <p className="font-semibold text-center mt-5 mb-3 text-lg">
          Recent Reviews
        </p>
      </div>
      {reviews
        ? reviews.map((review, index) => (
            <div
              key={index}
              className="flex flex-col rounded-md shadow-sm border-b-2 border-gray-200 px-2 py-2"
            >
              {/* Reviewer picture and username and date of review */}
              <div className="flex align-center items-center justify-between mb-2">
                <div className="flex items-center gap-2">
                  <img
                    src={
                      review.reviewerProfilePictureURL
                        ? review.reviewerProfilePictureURL
                        : profilePicturePlaceholder
                    }
                    alt="profilePicture"
                    className="w-10 h-10 rounded-full"
                  ></img>
                  <div className="mb-2">
                    <p className="mb-2">{review.reviewerUsername}</p>
                    <StarRating rating={review.rating} />
                  </div>
                </div>
                <p className="text-sm">{formatTimestamp(review.createdAt)}</p>
              </div>
              <p className="text-sm">{review.comment}</p>
            </div>
          ))
        : null}
    </div>
  );
};

export default ListingItemLenderReviews;
