// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDtY6YeJpr2WBpe6C7x2_4VgKwKIh9YmpQ",
  authDomain: "rentalsireland-c9abf.firebaseapp.com",
  projectId: "rentalsireland-c9abf",
  storageBucket: "rentalsireland-c9abf.appspot.com",
  messagingSenderId: "646192729396",
  appId: "1:646192729396:web:50e18d57e52df3a0d12961",
  measurementId: "G-JENPF0Y2J1",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
