const categories = [
  {
    name: "Film & Photography",
    subcategories: [
      { name: "Cameras" },
      { name: "Lenses" },
      { name: "Tripods & Stands" },
      { name: "Lighting Equipment" },
      { name: "Monitors & Displays" },
      { name: "Camera Accessories" },
      { name: "Other" },
    ],
  },
  {
    name: "Electronics",
    subcategories: [
      { name: "Audio Equipment" },
      { name: "Video Equipment" },
      { name: "Drones & Accessories" },
      { name: "Home Appliances" },
      { name: "Gaming Consoles & Accessories" },
      { name: "Other" },
    ],
  },
  {
    name: "Sports & Outdoor Gear",
    subcategories: [
      { name: "Bicycles" },
      { name: "Camping Equipment" },
      { name: "Water Sports Gear" },
      { name: "Fitness Equipment" },
      { name: "Skiing & Snowboarding Gear" },
      { name: "Other" },
    ],
  },
  {
    name: "Tools & DIY Equipment",
    subcategories: [
      { name: "Power Tools" },
      { name: "Hand Tools" },
      { name: "Gardening Tools" },
      { name: "Home Improvement Tools" },
      { name: "Other" },
    ],
  },
  {
    name: "Home & Living",
    subcategories: [
      { name: "Furniture" },
      { name: "Home Decor" },
      { name: "Kitchenware" },
      { name: "Appliances" },
      { name: "Other" },
    ],
  },
  {
    name: "Event & Party Supplies",
    subcategories: [
      { name: "Tables & Chairs" },
      { name: "Party Decorations" },
      { name: "Audio & Visual Equipment" },
      { name: "Tents & Canopies" },
      { name: "Catering Equipment" },
      { name: "Other" },
    ],
  },
  {
    name: "Travel & Adventure Gear",
    subcategories: [
      { name: "Luggage & Travel Bags" },
      { name: "Travel Accessories" },
      { name: "Outdoor Clothing" },
      { name: "Navigation Tools" },
      { name: "Other" },
    ],
  },
  {
    name: "Musical Instruments",
    subcategories: [
      { name: "Guitars & Accessories" },
      { name: "Keyboards & Pianos" },
      { name: "Drums & Percussion Instruments" },
      { name: "Amplifiers & Effects Pedals" },
      { name: "Other" },
    ],
  },
  {
    name: "Baby & Child Gear",
    subcategories: [
      { name: "Strollers & Car Seats" },
      { name: "Toys & Games" },
      { name: "Baby Monitors" },
      { name: "High Chairs & Playpens" },
      { name: "Other" },
    ],
  },
  {
    name: "Fashion & Costumes",
    subcategories: [
      { name: "Clothing Rentals" },
      { name: "Costume Rentals" },
      { name: "Accessories" },
      { name: "Other" },
    ],
  },
  {
    name: "Other",
    subcategories: [],
  },
];

export default categories;
