import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home.jsx";
import Listing from "./pages/Listing.jsx";
import SignIn from "./pages/SignIn.jsx";
import SignUp from "./pages/SignUp.jsx";
import NewListing from "./pages/NewListing.jsx";
import MyListings from "./pages/MyListings.jsx";
import SearchResults from "./pages/SearchResults";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import TermsAndConditions from "./pages/TermsAndCondtitions.jsx";
import Navbar from "./components/Navbar";
import MyRentals from "./pages/MyRentals.jsx";
import MyProfile from "./pages/MyProfile.jsx";
import Favourites from "./pages/Favourites.jsx";
import Inbox from "./pages/Inbox.jsx";
import EditListing from "./pages/EditListing.jsx";
import CheckAvailability from "./pages/CheckAvailability.jsx";
import UserProfile from "./pages/UserProfile.jsx";
import ConfirmRental from "./pages/ConfirmRental.jsx";
import MyBookings from "./pages/MyBookings.jsx";
import NotFound from "./pages/NotFound.jsx";
import ReviewPage from "./pages/ReviewPage.jsx";
import ForgotPassword from "./pages/ForgotPassword.jsx";
import { AuthProvider } from "./context/AuthContext";
import LendersHub from "./pages/LendersHub.jsx";
import Insurance from "./pages/Insurance.jsx";
import InsuranceClaim from "./pages/InsuranceClaim.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import EmailVerification from "./pages/EmailVerification.jsx";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Router>
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/my-listings" element={<MyListings />} />
            <Route path="/lenders-hub" element={<LendersHub />} />
            <Route path="/new-listing" element={<NewListing />} />
            <Route path="/edit-listing/:listingId" element={<EditListing />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/inbox" element={<Inbox />} />
            <Route path="/favourites" element={<Favourites />} />
            <Route path="/my-rentals" element={<MyRentals />} />
            <Route path="/my-bookings" element={<MyBookings />} />
            <Route path="/check-availability" element={<CheckAvailability />} />
            <Route path="/confirm-rental" element={<ConfirmRental />} />
            {/* <Route
              path="/listings/:location/:listingId"
              element={<Listing />}
            /> */}
            <Route path="/listings/:listingId" element={<Listing />} />
            <Route path="/users/:userUid" element={<UserProfile />} />
            <Route path="/listings/:searchTerm" element={<SearchResults />} />
            <Route path="/search-results/" element={<SearchResults />} />

            <Route
              path="/review/:rentalRequestId/:revieweeUid"
              element={<ReviewPage />}
            />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/email-verification" element={<EmailVerification />} />

            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/insurance-claim" element={<InsuranceClaim />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <ToastContainer />
      </AuthProvider>
    </>
  );
};

export default App;
