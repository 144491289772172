import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import GoogleMapLoader from "./GoogleMapLoader";

const libraries = ["places"];

const GoogleLocationSearch = ({ onLocationSelect }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  // Helper function to extract address components
  const extractAddressComponents = (addressComponents) => {
    const components = {
      country: "",
      county: "",
      locality: "",
      postal_code: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes("country")) {
        components.country = component.long_name;
      }
      if (
        types.includes("administrative_area_level_1") ||
        types.includes("administrative_area_level_2") ||
        types.includes("administrative_area_level_3")
      ) {
        components.county = component.long_name;
      }
      if (types.includes("locality")) {
        components.locality = component.long_name;
      }
      if (types.includes("postal_code")) {
        components.postal_code = component.long_name;
      }
    });

    return components;
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        console.error("No details available for input: " + place.name);
        return;
      }

      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      const formattedAddress = place.formatted_address || place.name;

      // Update input value to the formatted address
      setInputValue(formattedAddress);

      // Extract address components
      const addressComponents = extractAddressComponents(
        place.address_components || []
      );

      // Log the place and extracted components for debugging
      console.log("Selected place:", place);
      console.log("Extracted address components:", addressComponents);

      // Prepare the location data to pass to the parent component
      const locationData = {
        geolocation: { lat, lng },
        formattedAddress,
        addressComponents: addressComponents,
      };

      if (onLocationSelect) {
        onLocationSelect(locationData);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return (
    <GoogleMapLoader>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={{
          // types: ["(regions)"],
          componentRestrictions: { country: "ie" },
          fields: [
            "address_components",
            "geometry",
            "formatted_address",
            "name",
          ],
        }}
      >
        <input
          type="text"
          placeholder="Search Suburb, Town, City, or County..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </Autocomplete>
    </GoogleMapLoader>
  );
};

export default GoogleLocationSearch;
