import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import Spinner from "../components/Spinner";
import ListingCard from "../components/ListingCard"; // Import your ListingCard component
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { doc, getDoc, updateDoc, arrayRemove } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const Favourites = () => {
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchFavouriteListings = async () => {
      setLoading(true);
      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().favourites) {
          const favouritesList = userSnap.data().favourites;
          const listings = await Promise.all(
            favouritesList.map(async (listingId) => {
              const listingRef = doc(db, "listings", listingId);
              const docSnap = await getDoc(listingRef);
              return {
                listingDetails: { id: docSnap.id, ...docSnap.data() },
              };
            })
          );
          setFavourites(listings);
        } else {
          setFavourites([]);
        }
      } catch (error) {
        console.error("Failed to fetch favourite listings: ", error);
        toast.error("Failed to load favourites");
      }
      setLoading(false);
    };

    fetchFavouriteListings();
  }, [currentUser.uid]);

  // Function to remove a favourite
  // const removeFavourite = async (listingId) => {
  //   const userRef = doc(db, "users", currentUser.uid);
  //   try {
  //     console.log("listingId", listingId);
  //     await updateDoc(userRef, {
  //       favourites: arrayRemove(listingId),
  //     });
  //     setFavourites(
  //       favourites.filter((fav) => fav.listingDetails.id !== listingId)
  //     );
  //     toast.success("Removed from favourites!");
  //   } catch (error) {
  //     console.error("Error removing favourite: ", error);
  //     toast.error("Failed to remove from favourites.");
  //   }
  // };

  const removeFavourite = (listingId) => {
    setFavourites(
      favourites.filter((fav) => fav.listingDetails.id !== listingId)
    );
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
          My Favourites
        </h1>
      </div>

      {favourites.length > 0 ? (
        <div className="flex justify-center w-full mt-5">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-10 max-w-6xl mx-auto">
            {favourites.map((favourite, index) => (
              <ListingCard
                key={index}
                listingDetails={favourite.listingDetails}
                onRemove={() => removeFavourite(favourite.listingDetails.id)}
              />
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center text-lg font-semibold">No favourites yet</p>
      )}
    </div>
  );
};

export default Favourites;
