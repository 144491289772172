import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  getDoc,
  addDoc,
  where,
  serverTimestamp,
  updateDoc,
  arrayRemove,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCheck,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import MyBookingsMobile from "../components/MyBookingsMobile";
import formatCurrency from "../utils/formatCurrency";
import notFound from "../assets/notFound.png";

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [renterDetails, setRenterDetails] = useState({});
  const [selectedBooking, setSelectedBooking] = useState({});
  const [hoveredBookingId, setHoveredBookingId] = useState(null);
  const [showCancelBookingButton, setShowCancelBookingButton] = useState(true);
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const fetchListingDetails = async (listingId) => {
    const docRef = doc(db, "listings", listingId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : { title: "Listing not found" };
  };

  const fetchRenterDetails = async (renterUid) => {
    const docRef = doc(db, "users", renterUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setRenterDetails({ id: docSnap.id, ...docSnap.data() });
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      if (!currentUser) return;

      const bookingsRef = collection(db, "users", currentUser.uid, "bookings");
      const q = query(bookingsRef, orderBy("createdAt", "desc"));
      try {
        const querySnapshot = await getDocs(q);
        const bookingsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const bookingData = doc.data();
            const listingDetails = await fetchListingDetails(
              bookingData.listingId
            );
            const renterDetails = await fetchRenterDetails(
              bookingData.renterUid
            );

            return {
              ...bookingData,
              id: doc.id,
              listingDetails,
              renterUsername: renterDetails?.username || "Unknown",
              formattedStartDate: firestoreTimestampToDate(
                bookingData.startDate
              ).toLocaleDateString(),
              formattedEndDate: firestoreTimestampToDate(
                bookingData.endDate
              ).toLocaleDateString(),
            };
          })
        );

        console.log(bookings);

        setBookings(bookingsData);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchBookings();
  }, [currentUser]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleShowCancelBooking = (booking) => {
    setShowCancellationModal(true);
    setSelectedBooking(booking);
  };

  const processUserRefund = async () => {
    const body = JSON.stringify({
      payment_intent: selectedBooking.paymentIntent.id,
      amount: selectedBooking.rentalCost, // conversion to lowest value e.g. cent is handled server side
    });
    try {
      const response = await fetch(
        `https://createstriperefund-createstriperefund-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Refund processed:", data);
    } catch (error) {
      console.error("Error processing refund:", error.message);
    }
  };

  const deleteBookingFromListingDocument = async () => {
    try {
      const listingRef = doc(db, "listings", selectedBooking.listingId);

      const listingDoc = await getDoc(listingRef);

      if (!listingDoc.exists()) {
        console.error("Listing not found");
        return;
      }

      const { bookings } = listingDoc.data();

      console.log(bookings);

      // Find the booking to be removed
      const bookingToRemove = bookings.find(
        (booking) => booking.bookingId === selectedBooking.bookingId
      );

      if (!bookingToRemove) {
        console.error("Booking not found in listing");
        return;
      }

      // Remove the booking from the bookings array
      await updateDoc(listingRef, {
        bookings: arrayRemove(bookingToRemove),
      });

      console.log("Booking successfully removed from listing document.");
    } catch (error) {
      console.error("Error removing booking from listing document:", error);
    }
  };

  const sendCancellationEmailToRenter = async () => {
    const body = JSON.stringify({
      firstName: renterDetails.firstName,
      email: renterDetails.email,
      listingTitle: selectedBooking.listingDetails.title,
      lenderUsername: currentUser.username,
      reason: inputValue,
    });
    try {
      const response = await fetch(
        `https://cancelbookingemail-sendcancelbookingemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Cancellation email sent:", data);
    } catch (error) {
      console.error("Failed to send cancellation email:", error);
    }
  };

  const deleteRentalRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedBooking.id,
    });
    try {
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Rental request deleted:", data);
    } catch (error) {
      console.error("Failed to delete rental request:", error);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  const deleteBookingForLenderAndReduceBookingCount = async () => {
    try {
      const bookingRef = doc(
        db,
        "users",
        currentUser.uid,
        "bookings",
        selectedBooking.id
      );
      await deleteDoc(bookingRef);

      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        bookingCount: increment(-1),
      });

      console.log("Booking deleted and booking count reduced.");
    } catch (error) {
      console.error("Failed to delete booking:", error);
    }
  };

  const deleteRentalForRenterAndReduceRentalCount = async () => {
    try {
      let bookingId = selectedBooking.bookingId;

      const q = query(
        collection(db, "users", selectedBooking.renterUid, "rentals"),
        where("bookingId", "==", bookingId)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (rentalDoc) => {
        const rentalRef = doc(
          db,
          "users",
          selectedBooking.renterUid,
          "rentals",
          rentalDoc.id
        );

        await deleteDoc(rentalRef);

        const renterRef = doc(db, "users", selectedBooking.renterUid);
        await updateDoc(renterRef, {
          rentalCount: increment(-1),
        });

        console.log(`Rental deleted and rental count reduced for renter.`);
      });
    } catch (error) {
      console.error("Failed to delete rental for renter:", error);
    }
  };

  const createNewCancellationDocument = async () => {
    const cancellationData = {
      userUid: currentUser.uid,
      listingId: selectedBooking.listingId,
      cancellationReason: inputValue,
      cancellationDate: serverTimestamp(),
      startDate: selectedBooking.startDate,
      endDate: selectedBooking.endDate,
      cancellationType: "lender-cancellation",
    };

    try {
      await addDoc(collection(db, "cancellations"), cancellationData);
      console.log("New cancellation document created.");
    } catch (error) {
      console.error("Failed to create cancellation document:", error);
      toast.error("Failed to process cancellation.");
    }
  };

  const incremenetCancellationCountForLender = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      cancellationCount: increment(1),
    });
    console.log("User cancellation count incremented.");
  };

  const removeBookingFromState = () => {
    setBookings((prevBookings) =>
      prevBookings.filter((booking) => booking.id !== selectedBooking.id)
    );
  };

  const handleCancelBooking = async () => {
    setIsCancelling(true);

    try {
      await processUserRefund();
      await deleteRentalRequest();
      await deleteBookingFromListingDocument();
      await deleteRentalForRenterAndReduceRentalCount();
      await deleteBookingForLenderAndReduceBookingCount();
      await createNewCancellationDocument();
      await incremenetCancellationCountForLender();
      await sendCancellationEmailToRenter();

      toast.success("Booking has been cancelled successfully.", {
        autoClose: 3000,
      });

      removeBookingFromState();
    } catch (error) {
      console.error("Failed to cancel booking:", error);
      toast.error("Failed to cancel booking. Please try again.", {
        autoClose: 3000,
      });
    } finally {
      setShowCancellationModal(false);
      setInputValue("");
      setIsCancelling(false);
    }
  };

  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div className="container mx-auto py-5">
      <Modal
        isOpen={showCancellationModal}
        onRequestClose={() => setShowCancellationModal(false)}
        contentLabel="Cancellation Modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="space-y-6">
          {/* Header */}
          <h2 className="text-2xl font-semibold text-center">
            Are you sure you want to cancel this booking?
          </h2>

          {/* Warning Message */}
          <div className="space-y-2">
            <p className="text-sm text-gray-700">
              Cancellations are monitored and recorded in our system. Frequent
              cancellations can lead to account suspensions.
            </p>
            <p className="text-sm text-gray-700">
              To read our full cancellation policy, please visit{" "}
              <span
                onClick={() => navigate("/cancellation-policy")}
                className="text-blue-600 hover:underline cursor-pointer"
              >
                Cancellation Policy
              </span>
              .
            </p>
          </div>

          {/* Input Section */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Provide reason for cancellation{" "}
              <span className="text-red-500">*</span>
            </label>
            <p className="text-xs text-gray-500">
              This message will be sent to the renter and logged in our system.
            </p>
            <textarea
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500 text-sm"
              placeholder="Reason for cancellation..."
              value={inputValue}
              onChange={handleInputChange}
              rows={3}
            />
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-3">
            <button
              className="btn-cancel px-4"
              onClick={() => setShowCancellationModal(false)}
            >
              <FontAwesomeIcon icon={faCancel} className="mr-2" /> No, keep
              booking
            </button>
            <button
              className={`py-2 px-4 rounded-md flex items-center ${
                inputValue.length < 6
                  ? "bg-gray-300 disabled font-semibold"
                  : "btn-continue"
              }`}
              onClick={handleCancelBooking}
              disabled={isCancelling || inputValue.length < 5}
            >
              {isCancelling ? (
                <BeatLoader color="white" size={8} />
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheck} className="mr-2" /> Yes,
                  cancel booking
                </>
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex w-full justify-center mb-5">
        <h1 className="w-2/3 text-center text-xl font-bold header__center">
          My Bookings
        </h1>
      </div>

      {/* Mobile View - Render the mobile component */}
      <div className="block lg:hidden">
        <MyBookingsMobile
          bookings={bookings}
          handleShowCancelBooking={handleShowCancelBooking}
        />
      </div>

      {/* Desktop View - Render the table */}
      <div className="hidden lg:block w-full">
        {bookings.length > 0 ? (
          <table className="min-w-full table-auto text-center bg-white shadow-lg rounded-lg">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="px-6 py-3 font-semibold">Item</th>
                <th className="px-6 py-3 font-semibold">Renter</th>
                <th className="px-6 py-3 font-semibold">Days</th>
                <th className="px-6 py-3 font-semibold">Rate</th>
                <th className="px-6 py-3 font-semibold">Earnings</th>
                <th className="px-6 py-3 font-semibold">Insurance</th>
                <th className="px-6 py-3 font-semibold">Start Date</th>
                <th className="px-6 py-3 font-semibold">End Date</th>
                <th className="px-6 py-3 font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking, index) => (
                <tr
                  key={booking.id}
                  className={`bg-white border-b ${
                    index % 2 === 0 ? "bg-gray-100" : ""
                  } hover:bg-gray-100`}
                >
                  <td
                    className="border px-6 py-4 cursor-pointer hover:font-semibold"
                    onClick={() => navigate(`/listings/${booking.listingId}`)}
                  >
                    {booking.listingDetails.title}
                  </td>
                  <td
                    className="border px-6 py-4 cursor-pointer hover:font-semibold"
                    onClick={() => navigate(`/users/${booking.renterUid}`)}
                  >
                    {booking.renterUsername}
                  </td>
                  <td className="border px-6 py-4">{booking.numberOfDays}</td>
                  <td className="border px-6 py-4">
                    €{formatCurrency(booking.rateCents)}
                  </td>
                  <td className="border px-6 py-4 relative">
                    €{formatCurrency(booking.netEarningsCents)}
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="ml-2 text-gray-600 text-lg cursor-pointer"
                      onMouseEnter={() => setHoveredBookingId(booking.id)}
                      onMouseLeave={() => setHoveredBookingId(null)}
                    />
                    {hoveredBookingId === booking.id && (
                      <div className="absolute left-0 top-10 bg-gray-800 text-white p-2 rounded-md shadow-lg z-50 w-64 text-left">
                        {booking.insurancePackage ? (
                          <div>
                            <p className="font-semibold">Earnings Breakdown</p>
                            <p>
                              <strong>Rental total:</strong> €
                              {/* {booking.earnings.toFixed(2)} */}
                              {formatCurrency(booking.grossEarningsCents)}
                            </p>
                            <p>
                              <strong>Package fee:</strong> - €
                              {formatCurrency(
                                booking.insurancePackage.feeCents
                              )}
                            </p>
                            <p>
                              <strong>Final earnings:</strong> €
                              {formatCurrency(booking.netEarningsCents)}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <p className="font-semibold">Earnings Breakdown</p>
                            <p>No insurance package applied</p>
                            <p>
                              <strong>Total Earnings:</strong> €
                              {formatCurrency(
                                booking.earningsCents - booking.ins
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="border px-6 py-4">
                    {booking.insurancePackage
                      ? booking.insurancePackage.name
                      : "None"}
                  </td>
                  <td className="border px-6 py-4">
                    {booking.formattedStartDate}
                  </td>
                  <td className="border px-6 py-4">
                    {booking.formattedEndDate}
                  </td>
                  <td className="border px-6 py-4">
                    {firestoreTimestampToDate(booking.startDate) >
                    new Date() ? (
                      <button
                        onClick={() => handleShowCancelBooking(booking)}
                        className="btn-cancel px-2 py-1"
                      >
                        <FontAwesomeIcon icon={faCancel} /> Cancel Booking
                      </button>
                    ) : (
                      <div className="p-1 bg-gray-100 rounded-lg">N/A</div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="flex flex-col justify-center align-center items-center">
            <img src={notFound} alt="not-found" className="w-48" />
            <p className="font-semibold">No bookings found...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyBookings;
