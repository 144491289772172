import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUnlockAlt,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { app } from "../firebase.config";
import Footer from "../components/Footer";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const onChange = (e) => setEmail(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth(app);
      await sendPasswordResetEmail(auth, email);
      toast.success(`Email has been sent to ${email}`);
      navigate("/sign-in");
    } catch (error) {
      toast.error("Could not send reset email");
    }
  };

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
          Forgot Password
        </h1>
      </div>
      {/* Logon Card */}
      <div className="flex justify-center mt-4 sm:mt-5 mb-4 sm:mb-0">
        <div className="border-2 rounded-2xl border-gray-300/80 py-4 px-3 shadow-inner shadow-md bg-gray-50">
          {/* Card icon */}
          <div className="text-center">
            <FontAwesomeIcon
              icon={faUnlockAlt}
              color="black"
              size="2x"
              className="mb-2"
            />

            {/* Heading */}
            <p className="mt-2 text-lg font-semibold">Reset Password</p>
          </div>

          {/* Logon Fields */}
          <div className="flex">
            <form onSubmit={onSubmit}>
              <div className="flex flex-col py-2 w-64 mb-2">
                <label className="mb-2 text-xs sm:text-sm font-bold ">
                  Email
                </label>
                <input
                  className="border text-sm sm:text-base outline-none focus:border-emerald-500"
                  type="email"
                  id="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Enter email"
                ></input>
              </div>

              <div className="text-center rounded-md py-2 mt-3  mb-3">
                <button className="w-full btn-primary">
                  <FontAwesomeIcon icon={faArrowAltCircleRight} color="white" />{" "}
                  Send Reset Link
                </button>
              </div>
              {/* Sign Up Instead */}
              <div
                onClick={() => navigate("/sign-in")}
                className="text-center py-2 mt-5 border-gray-300 duration-300 font-semibold hover:font-bold hover:cursor-pointer mb-3"
              >
                <button className="text-lg text-teal-500">
                  Sign In Instead{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
