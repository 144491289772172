import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faHashtag,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";

const EnquireModal = ({ isOpen, onClose, listing }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();

  const today = new Date();
  const { bookings } = listing;

  // Function to get excluded dates based on bookings
  const getExcludedDates = () => {
    const excludedDates = [];
    bookings.forEach((booking) => {
      const start = new Date(firestoreTimestampToDate(booking.startDate));
      const end = new Date(firestoreTimestampToDate(booking.endDate));
      for (
        let day = new Date(start);
        day <= end;
        day.setDate(day.getDate() + 1)
      ) {
        excludedDates.push(new Date(day));
      }
    });
    return excludedDates;
  };

  const excludedDates = bookings ? getExcludedDates() : [];

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleContinue = () => {
    console.log(startDate, endDate);
    onClose();
    navigate("/check-availability", {
      state: { startDate, endDate, listing },
    });
  };

  const calculateDaysBetweenDates = () => {
    if (!startDate || !endDate) return 0;
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  };

  return (
    <div className="">
      <div className="">
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="Enquire Modal"
          style={customStyles}
          ariaHideApp={false}
        >
          <div
            className="flex justify-end hover:cursor-pointer hover:text-gray-500 duration-300"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </div>
          <h2 className="text-center text-lg mb-5 font-bold">
            Select Booking Dates
          </h2>
          <div className="flex justify-center">
            <DatePicker
              selected={startDate}
              onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
              }}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              minDate={today}
              excludeDates={excludedDates}
              inline
              monthsShown={2}
            />
          </div>
          <div className="flex justify-center gap-3 mt-5">
            <button
              className="px-5 sm:px-10 btn-delete text-sm sm:text-base"
              onClick={handleClearDates}
            >
              Clear dates
            </button>
            <button
              className="px-5 sm:px-10 btn-continue text-sm sm:text-base"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
          {startDate && endDate && (
            <div className="flex flex-col gap-2 pt-5">
              <div className="flex gap-1 text-sm sm:text-base">
                <FontAwesomeIcon icon={faCalendarCheck} />
                <span className="font-semibold ">Selected duration:</span>{" "}
                {startDate.toDateString()} - {endDate.toDateString()}
              </div>
              <div className="flex gap-1 text-sm sm:text-base">
                <FontAwesomeIcon icon={faHashtag} />
                <span className="font-semibold">Number of days:</span>{" "}
                {calculateDaysBetweenDates()}
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default EnquireModal;
