import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faX } from "@fortawesome/free-solid-svg-icons";
import {
  doc,
  deleteDoc,
  updateDoc,
  arrayRemove,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";

const ConfirmDeleteListingModal = ({
  isOpen,
  onClose,
  listing,
  currentUser,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [futureBookings, setFutureBookings] = useState([]);
  const [hasCheckedBookings, setHasCheckedBookings] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      checkForFutureBookings();
    }
  }, [isOpen]);

  const checkForFutureBookings = async () => {
    setIsLoading(true);
    try {
      // Fetch the latest listing data
      const listingRef = doc(db, "listings", listing.id);
      const listingSnap = await getDoc(listingRef);

      if (listingSnap.exists()) {
        const listingData = listingSnap.data();

        // Get the bookings array or default to an empty array
        const bookings = listingData.bookings || [];
        const currentDate = new Date();

        // Filter future bookings
        const futureBookings = bookings.filter((booking) => {
          if (!booking.endDate) {
            return false;
          }
          const bookingEndDate = firestoreTimestampToDate(booking.endDate);
          return bookingEndDate > currentDate;
        });

        setFutureBookings(futureBookings);
      } else {
        toast.error("Listing not found.", { autoClose: 5000 });
      }
    } catch (error) {
      console.error("Error fetching bookings: ", error);
      toast.error("Failed to check bookings. Please try again.", {
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
      setHasCheckedBookings(true);
    }
  };

  // Remove listingId from user ownedListings array
  const removeListingFromUserOwnedListings = async (listingId) => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      ownedListings: arrayRemove(listingId),
    });
  };

  const handleDeleteListing = async () => {
    setIsLoading(true);
    try {
      if (futureBookings.length > 0) {
        // There are future bookings, prevent deletion
        toast.error("Cannot delete listing with future bookings.", {
          autoClose: 5000,
        });
        setIsLoading(false);
        return;
      }

      // No future bookings, proceed with deletion
      const listingRef = doc(db, "listings", listing.id);
      await deleteDoc(listingRef);
      await removeListingFromUserOwnedListings(listing.id);
      toast.success("Listing has been deleted", { autoClose: 3000 });
      setIsLoading(false);
      navigate("/my-listings");
    } catch (error) {
      console.error("Error deleting listing: ", error);
      toast.error("Failed to delete listing. Please try again.", {
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  const customStyles = {
    content: {
      maxWidth: "600px",
      margin: "auto",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.95)",
      padding: "20px",
      borderRadius: "8px",
    },
  };

  return (
    <div className="w-full">
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Confirm delete listing"
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
          {isLoading && !hasCheckedBookings ? (
            <div className="flex justify-center items-center">
              <BeatLoader size={10} margin={2} color="#4A90E2" />
            </div>
          ) : futureBookings.length > 0 ? (
            <div>
              <p className="text-red-600 font-semibold mb-4">
                Cannot delete listing. The following future bookings exist:
              </p>
              <ul className="list-disc list-inside mb-4">
                {futureBookings.map((booking, index) => {
                  const startDate = firestoreTimestampToDate(booking.startDate);
                  const endDate = firestoreTimestampToDate(booking.endDate);
                  return (
                    <li key={index}>
                      From{" "}
                      <strong>
                        {startDate.toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </strong>{" "}
                      to{" "}
                      <strong>
                        {endDate.toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </strong>
                    </li>
                  );
                })}
              </ul>
              <p className="mb-4">
                Please fulfill or cancel these bookings before deleting the
                listing.
              </p>
              <div className="flex justify-center gap-5 mt-5">
                <button onClick={onClose} className="px-3 btn-back">
                  <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p className="mb-4">
                Are you sure you want to delete this listing?
              </p>
              <div className="flex justify-center gap-5 mt-5">
                <button onClick={onClose} className="px-3 btn-back">
                  <FontAwesomeIcon icon={faArrowLeft} /> Go Back
                </button>
                <button
                  onClick={handleDeleteListing}
                  className="px-3 btn-delete"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <BeatLoader size={10} margin={2} color="white" />
                  ) : (
                    <p>
                      <FontAwesomeIcon icon={faX} /> Delete Listing
                    </p>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmDeleteListingModal;
