import React from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";

// Used in AvailabilityRequestLender and AvailabilityConfrimedRequestor
// Item lender - showcase as they are confirming availability of the item
// Requestor - before they navigate to "confirm-rental" page to pay
const CheckListModal = ({ isOpen, onClose, handleConfirm }) => {
  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "600px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Checklist Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      <div>
        <h2 className="text-center font-bold text-lg mb-4">
          Last Minute Checklist
        </h2>
        <p className="font-semibold">Have you:</p>
        <p>- Confirmed pickup and delivery times?</p>
        <p>- Verified the lender/renter of the item?</p>

        <div>
          <div className="flex items-center align-center justify-center mt-3 mb-2 gap-3 text-white">
            <button onClick={() => onClose()} className="p-2 btn-delete">
              <FontAwesomeIcon icon={faCancel} /> Go back
            </button>
            <button className="p-2 btn-confirm" onClick={() => handleConfirm()}>
              <p>
                {" "}
                <FontAwesomeIcon icon={faCheck} /> Yes, proceed
              </p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckListModal;
