import React, { createContext, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore";

export const AuthContext = createContext({
  currentUser: null,
  isLoading: true,
  setCurrentUser: () => {},
});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const auth = getAuth();

  // Function to transform Firebase User to UserDetails
  const transformUser = async (user) => {
    // Fetch additional user details from Firestore
    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);
    console.log(userSnap.data());

    if (userSnap.exists()) {
      // Combine data from Firebase Auth and Firestore
      const userDetails = {
        uid: userSnap.id,
        username: userSnap.data().username,
        firstName: userSnap.data().firstName,
        lastName: userSnap.data().lastName,
        email: userSnap.data().email,
        phoneNumber: userSnap.data().phoneNumber,
        createdAt: userSnap.data().createdAt,
        addressComponents: userSnap.data().addressComponents,
        formattedAddress: userSnap.data().formattedAddress,
        idVerified: userSnap.data().idVerified,
        _geoloc: userSnap.data()._geoloc,
        favourites: userSnap.data().favourites,
        profilePictureURL: userSnap.data().profilePictureURL,
        emailVerified: user.emailVerified,
        stripeAccountId: userSnap.data().stripeAccountId,
        stripeOnboardingCompleted: userSnap.data().stripeOnboardingCompleted,
        stripeRequirementsDue: userSnap.data().stripeRequirementsDue,
      };
      return userDetails;
    }
    return null;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const transformedUser = await transformUser(user);
        setCurrentUser(transformedUser);
      } else {
        setCurrentUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isLoading, setCurrentUser }}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
