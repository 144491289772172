import React from "react";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import formatCurrency from "../../utils/formatCurrency";

const RentalConfirmedOwner = ({ message }) => {
  const {
    listingTitle,
    startDate,
    endDate,
    rentalCostCents,
    insurancePackage,
    numberOfDays,
    listingImage,
  } = message;

  console.log(message);

  const formattedStartDate =
    firestoreTimestampToDate(startDate).toLocaleDateString();
  const formattedEndDate =
    firestoreTimestampToDate(endDate).toLocaleDateString();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div className="">
      <h2 className="text-center font-semibold">Rental Confirmed</h2>
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-semibold mt-2">{listingTitle}</p>
      </div>
      <div className="flex flex-col items-center">
        <p>
          <span className="font-semibold">Total Earnings:</span> €
          {formatCurrency(rentalCostCents - insurancePackage.feeCents)}
        </p>
        <p>
          <span className="font-semibold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${formattedStartDate} - ${formattedEndDate}`}</p>
      </div>
      <p className="text-center my-4 font-bold text-emerald-400 text-lg"></p>
      <p className="text-sm text-center">
        This chat will be deleted 10 days after the rental end date.
      </p>
      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3"></div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>{" "}
      </div>
    </div>
  );
};

export default RentalConfirmedOwner;
