import React from "react";
import Footer from "../components/Footer";

const TermsAndConditions = () => {
  return (
    <>
      <div className="max-w-4xl mx-auto py-6">
        <h1 className="text-2xl font-bold mb-8 header__center">
          KitShare Terms and Conditions
        </h1>

        {/* 1. Introduction */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
          <p className="text-gray-700">
            Welcome to KitShare! These Terms and Conditions (
            <strong>"Terms"</strong>) govern your use of the KitShare platform
            and services, operated by KitShare Ltd (
            <strong>"KitShare", "we", "our", "us"</strong>). By accessing or
            using our services, you agree to these Terms. If you do not agree,
            please do not use our platform.
          </p>
        </section>

        {/* 2. Definitions */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">2. Definitions</h2>
          <ul className="list-disc list-inside space-y-2 text-gray-700">
            <li>
              <strong>Lender:</strong> A user who lists items for rent on
              KitShare.
            </li>
            <li>
              <strong>Renter:</strong> A user who rents items listed on
              KitShare.
            </li>
            <li>
              <strong>Item:</strong> Any object available for rent through the
              KitShare platform.
            </li>
            <li>
              <strong>Service Fee:</strong> A fee charged by KitShare for
              facilitating rental transactions.
            </li>
            <li>
              <strong>Insurance Packages:</strong> Protection plans available to
              Lenders for their listed items, including Bronze, Silver, and Gold
              packages.
            </li>
          </ul>
        </section>

        {/* 3. User Obligations */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">3. User Obligations</h2>
          <h3 className="text-xl font-semibold mb-2">Eligibility</h3>
          <p className="text-gray-700 mb-4">
            Users must be at least 18 years old and capable of entering into
            binding agreements.
          </p>
          <h3 className="text-xl font-semibold mb-2">Account Registration</h3>
          <p className="text-gray-700 mb-4">
            Users must provide accurate, current, and complete information
            during registration and maintain the security of their accounts.
          </p>
          <h3 className="text-xl font-semibold mb-2">Compliance</h3>
          <p className="text-gray-700">
            Users must comply with all local laws and regulations regarding
            their activities on the platform.
          </p>
        </section>

        {/* 4. Listings */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">4. Listings</h2>
          <h3 className="text-xl font-semibold mb-2">Accuracy</h3>
          <p className="text-gray-700 mb-4">
            Lenders are responsible for ensuring the accuracy of their listings,
            including descriptions, pricing, and availability.
          </p>
          <h3 className="text-xl font-semibold mb-2">Prohibited Items</h3>
          <p className="text-gray-700 mb-4">
            Certain items may not be listed on KitShare, including illegal
            items, hazardous materials, and items that violate intellectual
            property rights.
          </p>
          <h3 className="text-xl font-semibold mb-2">Ownership and Rights</h3>
          <p className="text-gray-700">
            Lenders must have the legal right to rent out items listed on the
            platform.
          </p>
        </section>

        {/* 5. Booking and Payments */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            5. Booking and Payments
          </h2>
          <h3 className="text-xl font-semibold mb-2">Service Fee</h3>
          <p className="text-gray-700 mb-4">
            KitShare charges a service fee, included in the rental price
            displayed to the Renter. This fee is deducted from the amount paid
            to the Lender.
          </p>
          <h3 className="text-xl font-semibold mb-2">Payments</h3>
          <p className="text-gray-700 mb-4">
            Transactions are processed through third-party payment providers.
            KitShare does not hold funds or provide financial services.
          </p>
          <h3 className="text-xl font-semibold mb-2">Cancellation Policy</h3>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>
              <strong>10% Cancellation Fee:</strong> If a Renter cancels within
              7 days of the rental start date.
            </li>
            <li>
              <strong>20% Cancellation Fee:</strong> If a Renter cancels the day
              before the rental.
            </li>
          </ul>
          <p className="text-gray-700">
            Cancellations and refunds are governed by the cancellation policy
            set by the Lender.
          </p>
        </section>

        {/* 6. Use of the Platform */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            6. Use of the Platform
          </h2>
          <h3 className="text-xl font-semibold mb-2">Prohibited Activities</h3>
          <p className="text-gray-700 mb-4">
            Users may not engage in fraudulent activities, misrepresentations,
            or use the platform for illegal purposes.
          </p>
          <h3 className="text-xl font-semibold mb-2">Communication</h3>
          <p className="text-gray-700">
            Users must use KitShare’s messaging system for all communications
            regarding the rental process. Off-platform transactions are
            prohibited.
          </p>
        </section>

        {/* 7. Liability */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">7. Liability</h2>
          <h3 className="text-xl font-semibold mb-2">
            Limitation of Liability
          </h3>
          <p className="text-gray-700 mb-4">
            KitShare is not liable for any loss, damage, or claims arising from
            transactions between Lenders and Renters. All transactions are
            conducted at the parties' own risk.
          </p>
          <h3 className="text-xl font-semibold mb-2">Indemnity</h3>
          <p className="text-gray-700">
            Users agree to indemnify and hold harmless KitShare from any claims
            or damages arising from their use of the platform.
          </p>
        </section>

        {/* 8. Insurance Coverage */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">8. Insurance Coverage</h2>
          <p className="text-gray-700 mb-4">
            KitShare offers three insurance packages for Lenders: Bronze,
            Silver, and Gold. Each package provides varying levels of protection
            against accidental damage and theft.
          </p>
          <ul className="list-disc list-inside text-gray-700 mb-4">
            <li>
              <strong>Bronze Package:</strong> Covers up to €300 for accidental
              damage and accidental loss.
            </li>
            <li>
              <strong>Silver Package:</strong> Covers up to €600 for accidental
              damage, accidental loss, and theft.
            </li>
            <li>
              <strong>Gold Package:</strong> Covers up to €1,000 for accidental
              damage, accidental loss, and theft.
            </li>
          </ul>
          <h3 className="text-xl font-semibold mb-2">
            Conditions of Liability for Renters
          </h3>
          <p className="text-gray-700">Renters are liable for:</p>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              Non-return or damage due to negligence (e.g., forgetfulness,
              carelessness, improper use).
            </li>
            <li>Non-return due to theft.</li>
          </ul>
        </section>

        {/* 9. Claim Process */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">9. Claim Process</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-700 mb-4">
            <li>
              <strong>Report the Issue:</strong> Lenders must report any theft
              or damage within 24 hours of the rental period's end via the
              KitShare claims portal.
            </li>
            <li>
              <strong>Submit Required Documentation:</strong>
              <ul className="list-disc list-inside ml-6 mt-2">
                <li>
                  <strong>Rental Agreement:</strong> Proof of the rental
                  transaction.
                </li>
                <li>
                  <strong>Condition Evidence:</strong> Time-stamped
                  photos/videos before and after the rental.
                </li>
                <li>
                  <strong>Police Report:</strong> Required for theft claims.
                </li>
                <li>
                  <strong>Repair Estimate:</strong> Needed for damages.
                </li>
              </ul>
            </li>
            <li>
              <strong>Investigation:</strong> KitShare will review documentation
              and may interview both parties.
            </li>
            <li>
              <strong>Decision:</strong> Expect a decision within 14 business
              days. Both parties will be notified.
            </li>
            <li>
              <strong>Payout:</strong> If approved, compensation will be
              provided up to the selected coverage limit within 7 business days.
            </li>
          </ol>
        </section>

        {/* 10. Dispute Resolution */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            10. Dispute Resolution
          </h2>
          <p className="text-gray-700">
            In case of disputes arising from these Terms, both parties may seek
            resolution through mediation. If unresolved, legal disputes shall be
            handled through binding arbitration according to the laws of
            Ireland.
          </p>
        </section>

        {/* 11. Termination */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">11. Termination</h2>
          <h3 className="text-xl font-semibold mb-2">Right to Terminate</h3>
          <p className="text-gray-700 mb-4">
            KitShare reserves the right to terminate user accounts for
            violations of these Terms or any applicable laws.
          </p>
          <h3 className="text-xl font-semibold mb-2">Effect of Termination</h3>
          <p className="text-gray-700">
            Upon termination, users must cease all use of the platform and
            settle any outstanding transactions.
          </p>
        </section>

        {/* 12. Privacy and Data Protection */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            12. Privacy and Data Protection
          </h2>
          <p className="text-gray-700">
            Users agree to KitShare’s Privacy Policy, which outlines how
            personal data is collected, used, and protected. KitShare implements
            security measures but cannot guarantee absolute security.
          </p>
        </section>

        {/* 13. Amendments */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">13. Amendments</h2>
          <p className="text-gray-700">
            KitShare reserves the right to modify these Terms at any time. Users
            will be notified of significant changes, and continued use of the
            platform constitutes acceptance of the new Terms.
          </p>
        </section>

        {/* 14. Miscellaneous */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">14. Miscellaneous</h2>
          <h3 className="text-xl font-semibold mb-2">Entire Agreement</h3>
          <p className="text-gray-700 mb-4">
            These Terms constitute the entire agreement between users and
            KitShare regarding the use of the platform.
          </p>
          <h3 className="text-xl font-semibold mb-2">Severability</h3>
          <p className="text-gray-700 mb-4">
            If any provision of these Terms is found to be unenforceable, the
            remaining provisions will remain in effect.
          </p>
          <h3 className="text-xl font-semibold mb-2">Waiver</h3>
          <p className="text-gray-700">
            Failure to enforce any provision of these Terms does not constitute
            a waiver of that provision.
          </p>
        </section>

        {/* 15. Contact Information */}
        <section className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            15. Contact Information
          </h2>
          <p className="text-gray-700">
            For any questions or concerns regarding these Terms, please contact
            us at{" "}
            <a
              href="mailto:support@kitshare.ie"
              className="text-blue-500 underline"
            >
              support@kitshare.ie
            </a>
            .
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
