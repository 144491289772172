import React, { useState, useEffect } from "react";
import ListOfChats from "../components/Messaging/ListOfChats";
import ChatWindow from "../components/Messaging/ChatWindow";
import { useAuth } from "../hooks/useAuth";
import { db } from "../firebase.config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  orderBy,
  getDoc,
  or,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";

const Inbox = () => {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [chatContainerHeight, setChatContainerHeight] = useState("100vh"); // Default to full viewport height
  const [recipientDetailsState, setRecipientDetailsState] = useState("");
  const [listOfChats, setListOfChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listingTitleState, setListingTitleState] = useState("");
  const [isMobileView, setIsMobileView] = useState(false); // State to track mobile view

  const { currentUser } = useAuth();

  // const updateRentalRequestSeenToTrue = async (chatId) => {
  //   const rentalRequestRef = doc(db, "rental-requests", chatId);
  //   const rentalRequestDoc = await getDoc(rentalRequestRef);
  //   if (rentalRequestDoc.exists) {
  //     if (rentalRequestDoc.data() && rentalRequestDoc.data().seen === false) {
  //       await updateDoc(rentalRequestRef, {
  //         seen: true,
  //       });
  //     }
  //   }
  // };

  // const decrementNewMessageCount = async () => {
  //   const userRef = doc(db, "users", currentUser.uid);
  //   await updateDoc(userRef, {
  //     newMessageCount: increment(-1),
  //   });
  // };

  const handleSelectChat = async (chatId) => {
    setSelectedChatId(chatId);

    // Fetch the correct recipient details based on the selected chat ID
    const selectedChat = listOfChats.find((chat) => chat.id === chatId);
    if (selectedChat) {
      const { recipientDetails, listingTitle } = selectedChat;
      setRecipientDetailsState(recipientDetails);
      setListingTitleState(listingTitle);
    }

    try {
      if (selectedChat) {
        // Remove current user's UID from 'unreadBy' array in Firestore
        const rentalRequestRef = doc(db, "rental-requests", chatId);
        await updateDoc(rentalRequestRef, {
          unreadBy: arrayRemove(currentUser.uid),
        });

        // Optionally, update local state to reflect the change
        setListOfChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatId
              ? {
                  ...chat,
                  unreadBy: chat.unreadBy?.filter(
                    (uid) => uid !== currentUser.uid
                  ),
                }
              : chat
          )
        );
      }
    } catch (error) {
      console.error("Error updating message status:", error);
      // Optional: Add error handling logic, such as displaying a toast notification
    }
  };

  // const handleSelectChat = async (chatId) => {
  //   setSelectedChatId(chatId);

  //   // Fetch the correct recipient details based on the selected chat ID
  //   const selectedChat = listOfChats.find((chat) => chat.id === chatId);
  //   if (selectedChat) {
  //     const { recipientDetails, listingTitle } = selectedChat;
  //     setRecipientDetailsState(recipientDetails); // Update the state with the recipient details
  //     setListingTitleState(listingTitle);
  //   }

  //   try {
  //     if (selectedChat) {
  //       // Update seen status and decrement new message count if current user is the lender
  //       if (currentUser.uid === selectedChat.lenderUid && !selectedChat.seen) {
  //         await updateRentalRequestSeenToTrue(chatId);
  //         await decrementNewMessageCount();
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error updating message status:", error);
  //     // Optional: Add error handling logic, such as displaying a toast notification
  //   }
  // };

  const fetchListingTitle = async (listingId) => {
    const docRef = doc(db, "listings", listingId);
    const docSnap = await getDoc(docRef);

    return docSnap.exists()
      ? docSnap.data().title
      : "Listing Title Unavailable";
  };

  const fetchRecipientdetails = async (userId) => {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    return {
      ...docSnap.data(),
      id: docSnap.id,
    };
  };

  const fetchChats = async () => {
    setIsLoading(true);
    const rentalRequestsRef = collection(db, "rental-requests");
    const q = query(
      rentalRequestsRef,
      or(
        where("renterUid", "==", currentUser.uid),
        where("lenderUid", "==", currentUser.uid)
      ),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    const chatsData = [];

    for (let doc of querySnapshot.docs) {
      const data = doc.data();
      // Fetch additional data for each chat
      const listingTitle = await fetchListingTitle(data.listingId);
      const recipientDetails = await fetchRecipientdetails(
        data.renterUid === currentUser.uid ? data.lenderUid : data.renterUid
      );

      // Combine data and add to state for ListOfChats component
      chatsData.push({
        id: doc.id,
        listingTitle,
        ...data,
        recipientDetails,
      });
    }
    setIsLoading(false);
    setListOfChats(chatsData);
  };

  useEffect(() => {
    const calculateChatContainerHeight = () => {
      const navbarHeight = 60; // Adjust this value based on your actual navbar height
      const height = `calc(100vh - ${navbarHeight}px)`;
      setChatContainerHeight(height);
    };

    const checkIfMobileView = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    calculateChatContainerHeight();
    checkIfMobileView();
    window.addEventListener("resize", calculateChatContainerHeight);
    window.addEventListener("resize", checkIfMobileView);

    // Fetch chat list on component mount
    fetchChats();

    return () => {
      window.removeEventListener("resize", calculateChatContainerHeight);
      window.removeEventListener("resize", checkIfMobileView);
    };
  }, []);

  const removeChat = (chatId) => {
    console.log("removing chatId", chatId);
    setListOfChats((prevChats) =>
      prevChats.filter((chat) => chat.id !== chatId)
    );
  };

  const goBackToList = () => {
    setSelectedChatId(null);
  };

  return (
    <div
      className="flex h-screen overflow-hidden"
      style={{ height: chatContainerHeight }}
    >
      {/* Conditional rendering based on whether it's a mobile view */}
      {isMobileView ? (
        selectedChatId ? (
          // Mobile view with a selected chat
          <div className="w-full h-full">
            <ChatWindow
              selectedChatId={selectedChatId}
              onDeleteChat={removeChat}
              recipientDetails={recipientDetailsState}
              listingTitle={listingTitleState}
              onBack={goBackToList} // Pass a function to handle going back to list
            />
          </div>
        ) : (
          // Mobile view without a selected chat
          <div className="w-full">
            <ListOfChats
              listOfChats={listOfChats}
              onSelectChat={handleSelectChat}
              currentUser={currentUser}
              selectedChatId={selectedChatId}
              isLoading={isLoading}
            />
          </div>
        )
      ) : (
        // Desktop view
        <>
          <div className="w-1/5 border-r border-gray-300 h-full">
            <ListOfChats
              listOfChats={listOfChats}
              onSelectChat={handleSelectChat}
              currentUser={currentUser}
              selectedChatId={selectedChatId}
              isLoading={isLoading}
            />
          </div>
          <div className="w-4/5">
            {selectedChatId ? (
              <ChatWindow
                selectedChatId={selectedChatId}
                onDeleteChat={removeChat}
                recipientDetails={recipientDetailsState}
                listingTitle={listingTitleState}
              />
            ) : (
              <div className="flex justify-center items-center h-full">
                <p>Select a chat to start messaging</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Inbox;
