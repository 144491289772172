import React, { useEffect, useState, useContext } from "react";
import { getAuth, applyActionCode } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext"; // Adjust the import path as necessary
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config"; // Adjust the import path as necessary

const EmailVerification = () => {
  const [status, setStatus] = useState("verifying"); // 'verifying', 'success', 'error'
  const [message, setMessage] = useState("Verifying your email...");
  const auth = getAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, setCurrentUser } = useContext(AuthContext); // Access the auth context

  useEffect(() => {
    // Parse query parameters from the URL
    const query = new URLSearchParams(location.search);
    const mode = query.get("mode");
    const actionCode = query.get("oobCode");

    if (mode === "verifyEmail" && actionCode) {
      handleVerifyEmail(actionCode);
    } else {
      setStatus("error");
      setMessage("Invalid verification link.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only run on component mount

  const handleVerifyEmail = async (actionCode) => {
    try {
      await applyActionCode(auth, actionCode);
      // Email verified successfully
      setStatus("success");
      setMessage("Your email has been verified successfully!");

      if (auth.currentUser) {
        // User is signed in, reload user data
        await auth.currentUser.reload();

        // Fetch updated user info
        const updatedUser = auth.currentUser;

        // Fetch additional user details from Firestore
        const userRef = doc(db, "users", updatedUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          // Combine data from Firebase Auth and Firestore
          const transformedUser = {
            uid: updatedUser.uid,
            email: updatedUser.email,
            emailVerified: updatedUser.emailVerified,
            // Include other fields from userSnap
            username: userSnap.data().username,
            firstName: userSnap.data().firstName,
            lastName: userSnap.data().lastName,
            phoneNumber: userSnap.data().phoneNumber,
            signUpTimestamp: userSnap.data().signUpTimestamp,
            location: userSnap.data().location,
            idVerified: userSnap.data().idVerified,
            _geoloc: userSnap.data()._geoloc,
            favourites: userSnap.data().favourites,
            profilePictureURL: userSnap.data().profilePictureURL,
            stripeAccountId: userSnap.data().stripeAccountId,
          };

          // Manually update the auth context
          setCurrentUser(transformedUser);
        } else {
          // If user data doesn't exist in Firestore, handle accordingly
          console.error("User data not found in Firestore.");
        }

        // Optionally, navigate to a different page
        navigate("/"); // Adjust the path as needed
      } else {
        // User is not signed in
        // Prompt the user to log in
        setMessage(
          (prevMessage) => prevMessage + " Please log in to continue."
        );
      }
    } catch (error) {
      // Handle error
      setStatus("error");
      setMessage(
        "Failed to verify email. The link may have expired or is invalid."
      );
      console.error("Error verifying email:", error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-3">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md mx-auto">
        {status === "verifying" && (
          <div>
            <h1 className="text-2xl font-semibold mb-4 text-center">
              Email Verification
            </h1>
            <p className="text-center">{message}</p>
          </div>
        )}
        {status === "success" && (
          <div>
            <h1 className="text-2xl font-semibold mb-4 text-center text-green-600">
              Email Verified!
            </h1>
            <p className="text-center mb-6">{message}</p>
            {auth.currentUser ? (
              <button
                onClick={() => navigate("/")}
                className="w-full btn-continue"
              >
                Go to Home
              </button>
            ) : (
              <button
                onClick={() => navigate("/sign-in")}
                className="w-full btn-continue"
              >
                Log In
              </button>
            )}
          </div>
        )}
        {status === "error" && (
          <div>
            <h1 className="text-2xl font-semibold mb-4 text-center text-red-600">
              Verification Failed
            </h1>
            <p className="text-center mb-4">{message}</p>
            <p className="text-center mb-6">
              If you have any issues or questions, please contact{" "}
              <a
                href="mailto:support@kitshare.ie"
                className="text-blue-500 underline"
              >
                support@kitshare.ie
              </a>{" "}
              for assistance.
            </p>
            <button onClick={() => navigate("/")} className="w-full btn-delete">
              Go to Home
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
