import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config"; // Adjust the import path as necessary

const useListing = (listingId) => {
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchListing = async () => {
      setLoading(true);
      const docRef = doc(db, "listings", listingId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setListing({ id: docSnap.id, ...docSnap.data() });
      } else {
        setListing(null);
      }
      setLoading(false);
    };

    if (listingId) {
      fetchListing();
    }
  }, [listingId]);

  return { listing, loading };
};

export default useListing;
