import React, { useState, useEffect } from "react";
import { doc, serverTimestamp, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import LenderInsuranceModal from "./LenderInsuranceModal";
import LenderVerificationModal from "../LenderVerificationModal";
import formatCurrency from "../../utils/formatCurrency";
import { getAuth } from "firebase/auth";
import { useAuth } from "../../hooks/useAuth";

const AvailabilityRequestLender = ({
  message,
  onOpenModal,
  recipientDetails,
  rentalRequestChatId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null); // For storing selected insurance package
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const { currentUser, setCurrentUser } = useAuth();

  // Destructure rental request message details
  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCostCents,
    rateCents,
    numberOfDays,
    listingImage,
    serviceFeeCents,
    rentalCostCents,
  } = message;

  const auth = getAuth();

  useEffect(() => {
    const fetchUserStripeStatus = async () => {
      if (auth.currentUser && !auth.currentUser.stripeOnboardingCompleted) {
        try {
          // Fetch the updated user data from Firestore
          const userDocRef = doc(db, "users", auth.currentUser.uid);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            const updatedStripeOnboardingCompleted =
              userData.stripeOnboardingCompleted;

            // Update the context and state with the fetched data
            setCurrentUser((prevUser) => ({
              ...prevUser,
              stripeOnboardingCompleted: updatedStripeOnboardingCompleted,
              idVerified: userData.idVerified, // Ensure idVerified is also kept updated
              stripeAccountId: userData.stripeAccountId,
            }));
          }
        } catch (error) {
          console.error("Error fetching user data from Firestore:", error);
        }
      }
    };

    fetchUserStripeStatus();
  }, [auth.currentUser, setCurrentUser]);

  // Handle deposit (if any)
  let depositCents = message.depositCents || null;

  // Get email and username of requestor
  const { email, firstName } = recipientDetails;

  // Convert Firestore Timestamps to JavaScript Dates
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  // Date formatting function
  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Readable start and end dates
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);

  // Send confirmation email after availability confirmation
  const sendAvailabilityConfirmedEmail = async () => {
    try {
      const body = JSON.stringify({
        firstName: firstName,
        email: email,
        lenderUsername: currentUser.username,
        listingTitle,
        numberOfDays,
        startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
        endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
        rateCents,
        serviceFeeCents,
        totalCostCents,
      });

      setIsLoading(true);
      const response = await fetch(
        `https://availabilityconfirmedemail-availabilityconfirmede-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
      console.log("Email sent successfully");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Failed to send confirmation email.");
    }
  };

  // Open the checklist modal
  const showVerificationOrChecklistModal = () => {
    if (
      !currentUser.idVerified ||
      !currentUser.stripeAccountId ||
      !currentUser.stripeOnboardingCompleted
    ) {
      setIsVerificationModalOpen(true);
    } else {
      setIsChecklistModalOpen(true);
    }
  };

  // Handle availability confirmation and insurance package selection
  const handleConfirmAvailability = async () => {
    if (!selectedPackage) {
      toast.error("Please select an insurance package.");
      return;
    }

    console.log(message);

    try {
      setIsLoading(true);
      const messageRef = doc(
        db,
        "rental-requests",
        rentalRequestChatId,
        "messages",
        message.id
      );

      // Update the message in Firestore to reflect the confirmation and insurance package
      await updateDoc(messageRef, {
        type: "availability-confirmed",
        insurancePackage: selectedPackage,
        createdAt: serverTimestamp(),
      });

      // Update the rental request document with the selected insurance package from the lender
      // Also change the status of the rental-request to availability-confirmed
      const rentalRequestRef = doc(db, "rental-requests", rentalRequestChatId);
      await updateDoc(rentalRequestRef, {
        insurancePackage: selectedPackage,
        status: "availability-confirmed",
      });

      // Send the email to the requestor
      await sendAvailabilityConfirmedEmail();

      setIsLoading(false);
      toast.success("Requestor has been notified of availability.", {
        autoClose: 3000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Failed to confirm availability.");
    }
  };

  // Format Firebase timestamp to Dublin timezone
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    const date = timestamp.toDate();
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div>
      {isVerificationModalOpen && (
        <LenderVerificationModal
          isOpen={isVerificationModalOpen}
          onClose={() => setIsVerificationModalOpen(false)}
          currentUser={currentUser}
        />
      )}

      {/* Insurance Modal */}
      <LenderInsuranceModal
        isOpen={isChecklistModalOpen}
        onClose={() => setIsChecklistModalOpen(false)}
        handleConfirm={handleConfirmAvailability}
        rentalRequest={message}
        setSelectedPackage={setSelectedPackage}
      />

      <h2 className="text-center font-semibold">Availability Request</h2>
      <p className="text-center my-5 new-line">{content}</p>

      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-semibold mt-2">{listingTitle}</p>
      </div>

      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Your earnings:</span> €
          {(rentalCostCents / 100).toFixed(2)}
        </p>
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${readableStartDate} - ${readableEndDate}`}</p>
        {depositCents && (
          <p>
            <span className="font-bold">Required deposit:</span> €
            {formatCurrency(depositCents)}
          </p>
        )}
      </div>

      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 btn-confirm"
          onClick={() => showVerificationOrChecklistModal()}
          disabled={isLoading}
        >
          {isLoading ? (
            <BeatLoader />
          ) : (
            <p>
              <FontAwesomeIcon icon={faCheck} /> Yes, it's available
            </p>
          )}
        </button>
        <button className="py-2 px-5 btn-delete" onClick={onOpenModal}>
          <FontAwesomeIcon icon={faX} /> Reject Request
        </button>
      </div>

      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>
      </div>
    </div>
  );
};

export default AvailabilityRequestLender;
