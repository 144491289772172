import React, { useState } from "react";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../utils/formatCurrency";

const MyRentalsMobile = ({ rentals, handleShowCancelRental }) => {
  const [openRentalId, setOpenRentalId] = useState(null);

  const toggleRentalDetails = (rentalId) => {
    setOpenRentalId(openRentalId === rentalId ? null : rentalId);
  };

  const navigate = useNavigate();

  return (
    <div className="flex flex-wrap gap-4 px-4 py-6">
      {rentals.map((rental) => (
        <div
          key={rental.id}
          className="w-full bg-white shadow-md rounded-lg p-4"
        >
          {/* rental Summary */}
          <div className="" onClick={() => toggleRentalDetails(rental.id)}>
            <div className="flex items-center justify-between">
              <h2
                className="text-lg font-bold hover:cursor-pointer hover:underline"
                onClick={() => navigate(`/listings/${rental.listingId}`)}
              >
                {rental.listingDetails.title}
              </h2>
              <div>
                {/* <img src={rental.imageURLs[0]} alt="rentalImage" /> */}
                <p className="text-sm font-semibold text-gray-500">
                  {rental.formattedStartDate} - {rental.formattedEndDate}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-2 text-sm text-gray-700">
              <p
                className="hover:cursor-pointer hover:underline hover:font-semibold"
                onClick={() => navigate(`/users/${rental.lenderUid}`)}
              >
                <span className="font-semibold">Lender: </span>
                {rental.lenderUsername}
              </p>
              <p>
                <span className="font-semibold">Number of days: </span>
                {rental.numberOfDays}
              </p>
              <p>
                <span className="font-semibold">Rental cost: </span>€
                {formatCurrency(rental.totalCostCents)}
              </p>

              <div className="flex justify-center w-full">
                {/* <button
                  onClick={() => handleShowCancelrental(rental)}
                  disabled={isCancelling}
                  className="btn-cancel w-1/2 px-3 py-1 text-sm"
                >
                  {isCancelling ? <BeatLoader /> : "Cancel rental"}
                </button> */}
                {firestoreTimestampToDate(rental.startDate) > new Date() ? (
                  <button
                    onClick={() => handleShowCancelRental(rental)}
                    className="btn-cancel px-2 py-1"
                  >
                    <FontAwesomeIcon icon={faCancel} /> Cancel Rental
                  </button>
                ) : (
                  <div className="px-5 py-2 bg-gray-100 rounded-lg font-semibold shadow-md">
                    N/A
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MyRentalsMobile;
