import { useState, useEffect } from "react";
import { db } from "../firebase.config";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import formatCurrency from "../utils/formatCurrency";
import {
  calculateTotalCostCents,
  calculateAverageRateCents,
} from "../utils/costCalculations";

const CheckAvailability = () => {
  const [requestMessage, setRequestMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listingLenderDetails, setListingLenderDetails] = useState({});
  const [depositCents, setDepositCents] = useState(0);
  const [numberOfDays, setNumberOfDays] = useState(0);

  // State to store calculated costs
  const [rentalCostCents, setRentalCostCents] = useState(0);
  const [serviceFeeCents, setServiceFeeCents] = useState(0);
  const [totalCostCents, setTotalCostCents] = useState(0);
  const [averageRateCents, setAverageRateCents] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { startDate, endDate, listing } = location.state;

  const { currentUser } = useAuth();

  const getListingLenderDetails = async (listingLenderUid) => {
    const lenderDocRef = doc(db, "users", listingLenderUid);
    const lenderSnap = await getDoc(lenderDocRef);
    if (lenderSnap.exists()) {
      setListingLenderDetails(lenderSnap.data());
    } else {
      setListingLenderDetails({ username: null, location: null });
    }
  };

  useEffect(() => {
    getListingLenderDetails(listing.lenderUid);
    setNumberOfDays(calculateDaysBetweenDates());
    if (listing.depositCents) setDepositCents(listing.depositCents);
  }, [listing, startDate, endDate]);

  const calculateDaysBetweenDates = () => {
    if (!startDate || !endDate) return 0;
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1; // Add 1 to include the end date in the count
  };

  // Calculate rental cost and store in state
  useEffect(() => {
    if (numberOfDays > 0 && listing) {
      const calculatedRentalCost = calculateTotalCostCents(
        numberOfDays,
        listing
      );
      const calculatedServiceFee = Math.round(calculatedRentalCost * 0.1);
      const calculatedTotalCost = calculatedRentalCost + calculatedServiceFee;

      setRentalCostCents(calculatedRentalCost);
      setServiceFeeCents(calculatedServiceFee);
      setTotalCostCents(calculatedTotalCost);

      const calculatedAverageRateCents = calculateAverageRateCents(
        numberOfDays,
        listing
      );
      setAverageRateCents(calculatedAverageRateCents);
    }
  }, [numberOfDays, listing]);

  // Rental details and calculations
  // const rentalCostCents = calculateTotalCost();
  // const serviceFeeCents = Math.round(rentalCostCents * 0.1);
  // const totalCostCents = rentalCostCents + serviceFeeCents;

  // Convert to Euros for display
  const rentalCost = formatCurrency(rentalCostCents);
  const serviceFee = formatCurrency(serviceFeeCents);
  const totalCost = formatCurrency(totalCostCents);

  const createUserRentalRequest = async () => {
    try {
      setIsLoading(true);

      // Prepare the rental request data
      const rentalRequestData = {
        listingId: listing.id,
        lenderUid: listing.lenderUid,
        renterUid: currentUser.uid,
        startDate: startDate,
        endDate: endDate,
        numberOfDays: numberOfDays,
        rentalCostCents: rentalCostCents,
        serviceFeeCents: serviceFeeCents,
        totalCostCents: totalCostCents,
        status: "open",
        createdAt: serverTimestamp(),
        unreadBy: [listing.lenderUid], // Include the lender's UID
        renterHasReviewed: false,
        lenderHasReviewed: false,
      };

      // Include deposit if it exists
      if (listing.depositCents) {
        rentalRequestData.depositCents = listing.depositCents;
      }

      // Add a new document in the rental-requests collection
      const rentalRequestDocument = await addDoc(
        collection(db, "rental-requests"),
        rentalRequestData
      );

      setIsLoading(false);
      return rentalRequestDocument;
    } catch (error) {
      console.error("Error submitting booking request: ", error);
      setIsLoading(false);
      toast.error("Failed to submit booking request.");
    }
  };

  const incrementLenderNewMessageCount = async () => {
    const lenderRef = doc(db, "users", listing.lenderUid);
    await updateDoc(lenderRef, {
      newMessageCount: increment(1),
    });
  };

  const createInitialChatMessage = async (rentalRequestDocumentId) => {
    try {
      setIsLoading(true);

      // Determine the average rate used
      // let averageRateCents = 0;
      // if (numberOfDays >= 7 && listing.sevenDayCostCents) {
      //   averageRateCents = Math.round(listing.sevenDayCostCents / 7);
      // } else if (numberOfDays >= 3 && listing.threeDayCostCents) {
      //   averageRateCents = Math.round(listing.threeDayCostCents / 3);
      // } else {
      //   averageRateCents = listing.dailyRateCents;
      // }

      const rentalRequestMessageData = {
        listingId: listing.id,
        listingTitle: listing.title,
        listingImage: listing.imageURLs[0],
        startDate: startDate,
        endDate: endDate,
        numberOfDays: numberOfDays,
        lenderUid: listing.lenderUid,
        renterUid: currentUser.uid,
        rateCents: averageRateCents,
        rentalCostCents: rentalCostCents,
        serviceFeeCents: serviceFeeCents,
        totalCostCents: totalCostCents,
        senderUid: currentUser.uid,
        receiverUid: listing.lenderUid,
        type: "availability-request",
        content: requestMessage,
        createdAt: serverTimestamp(),
      };

      if (listing.depositCents) {
        rentalRequestMessageData.depositCents = listing.depositCents;
      }

      // Add a new document in the messages collection
      await addDoc(
        collection(db, "rental-requests", rentalRequestDocumentId, "messages"),
        rentalRequestMessageData
      );

      toast.success(
        "The lender has been notified of your request. Keep an eye on your inbox for a response.",
        { autoClose: 5000 }
      );
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Error submitting booking request: ", error);
      setIsLoading(false);
      // Handle the error, possibly showing a notification to the user
    }
  };

  const createAvailabilityRequestEmail = async () => {
    // Earnings will be set to totalCost * 0.95, as we charge 5% for no insurance
    let earningsCents = calculateTotalCostCents(numberOfDays, listing) * 0.95;

    const body = JSON.stringify({
      firstName: listingLenderDetails.firstName,
      lenderEmail: listingLenderDetails.email,
      requestMessage: requestMessage,
      renterUsername: currentUser.username,
      listingTitle: listing.title,
      earningsCents: earningsCents,
      duration: numberOfDays,
      startDate: startDate.toDateString(),
      endDate: endDate.toDateString(),
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://availabilityrequestemail-availabilityrequestemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability request email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleMessageChange = (e) => {
    setRequestMessage(e.target.value);
  };

  const handleSubmitRequest = async () => {
    // Ensure user has submitted message
    if (requestMessage.trim().length === 0) {
      toast.error("You need to provide a message to the item lender.", {
        autoClose: 3000,
      });
    } else {
      const rentalRequestDocument = await createUserRentalRequest();
      if (rentalRequestDocument) {
        await createInitialChatMessage(rentalRequestDocument.id);
        await incrementLenderNewMessageCount();
        await createAvailabilityRequestEmail();
      }
    }
  };

  return (
    <div>
      <div className="flex px-5 flex-col-reverse sm:flex-row justify-center sm:gap-10 md:gap-20 mb-5">
        {/* Left hand side container - message request and buttons */}
        <div className="w-full p-3 sm:p-0 sm:w-1/3 my-4">
          <h2 className="font-semibold text-lg">Check Availability</h2>
          <p className="mb-4">
            Request confirmation of availability from the lender before you pay
            and verify.
          </p>
          <p className="font-semibold">Confirm with the lender:</p>
          <ul className="">
            <li>- Pickup and return times</li>
            <li>- Item condition</li>
            <li>- Deposit collection preference (cash, bank transfer, etc.)</li>
          </ul>

          <h2 className="font-semibold text-lg mt-5 mb-1">
            Message the Lender <span className="text-xs">(required)</span>
          </h2>

          <textarea
            value={requestMessage}
            onChange={handleMessageChange}
            placeholder="Send any details about your request including pickup times etc."
            className="text-sm w-full p-2 border rounded"
            rows={5}
          ></textarea>
          <div className="flex gap-5 justify-center mt-5">
            <button className="px-10 btn-back" onClick={() => navigate(-1)}>
              Back
            </button>
            <button
              disabled={requestMessage.trim().length === 0 || isLoading}
              onClick={handleSubmitRequest}
              className={`${
                requestMessage.trim().length === 0 || isLoading
                  ? "px-5 sm:px-10 btn-disabled"
                  : "px-5 sm:px-10 btn-continue"
              }`}
            >
              {isLoading ? <BeatLoader color="white" /> : "Submit Request"}
            </button>
          </div>
        </div>
        {/* Right hand side container - listing, user and pricing details */}
        <div className="w-full sm:w-1/4 p-3 sm:p-0">
          <h2 className="font-semibold text-lg my-4">{listing.title}</h2>
          {/* User details */}
          <div className="flex justify-between">
            <p className="text-base mb-2">
              <span className="font-semibold">
                {listingLenderDetails?.username ?? ""}
              </span>{" "}
              in {listingLenderDetails?.location?.town ?? ""}
            </p>
            <div className="flex items-center mb-2 gap-2">
              <p className="text-sm">
                {listingLenderDetails.averageReviewRating
                  ? listingLenderDetails.averageReviewRating.toFixed(1)
                  : "N/A"}{" "}
                <FontAwesomeIcon icon={faStar} color="gold" />
              </p>
              <p className="text-sm">
                {" "}
                (
                {listingLenderDetails.reviewCount > 0
                  ? listingLenderDetails.reviewCount
                  : 0}
                )
              </p>
            </div>
          </div>
          {/* Listing pricing details */}
          <div className="bg-gray-100 shadow-md flex flex-col gap-3 rounded-md p-2">
            <p>
              <span className="font-semibold">Number of days:</span>{" "}
              {numberOfDays}
            </p>
            <p>
              <span className="font-semibold">Dates:</span>{" "}
              {startDate.toDateString()} - {endDate.toDateString()}
            </p>
            {depositCents > 0 && (
              <p>
                <span className="font-semibold">Required Deposit:</span> €
                {formatCurrency(depositCents)}
              </p>
            )}
            {numberOfDays >= 7 && listing.sevenDayCostCents ? (
              <div className="bg-teal-500 text-center text-white font-semibold p-1 rounded-md shadow-sm">
                7+ day rate
              </div>
            ) : numberOfDays >= 3 && listing.threeDayCostCents ? (
              <div className="bg-teal-500 text-center text-white font-semibold p-1 rounded-md shadow-sm">
                3+ day rate
              </div>
            ) : null}
            <p>
              <span className="font-semibold">Rate:</span> €
              {(
                (numberOfDays >= 7 && listing.sevenDayCostCents
                  ? Math.round(listing.sevenDayCostCents / 7)
                  : numberOfDays >= 3 && listing.threeDayCostCents
                  ? Math.round(listing.threeDayCostCents / 3)
                  : listing.dailyRateCents) / 100
              ).toFixed(2)}
            </p>
            <p className="border-t-2 border-gray-400 pt-4">
              <span className="font-semibold">Rental Cost:</span> €{rentalCost}
            </p>
            <p>
              <span className="font-semibold">Service Fee (10%):</span> €
              {serviceFee}
            </p>
            {/* <p>
              <span className="font-semibold">Service Fee Total (10%):</span> €
              {serviceFee}
            </p>
            <p className="text-sm">
              - Service Fee: €{formatCurrency(serviceFeeCents * 0.77)}
            </p>
            <p className="text-sm">
              - VAT (23%): €{formatCurrency(serviceFeeCents * 0.23)}
            </p> */}
            <p className="border-t-2 border-gray-400 pt-4">
              <span className="font-semibold">Total:</span> €{totalCost}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckAvailability;
