import React, { useState, useEffect } from "react";
import ReviewForm from "../components/ReviewForm";
import { useParams } from "react-router-dom";
import { db } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";

const ReviewPage = () => {
  const [revieweeDetails, setRevieweeDetails] = useState(null);
  const [listingDetails, setListingDetails] = useState(null);
  const [rentalRequestDetails, setRentalRequestDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { rentalRequestId, revieweeUid } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch rental request details
        const rentalRequestRef = doc(db, "completed-rentals", rentalRequestId);
        const rentalRequestDoc = await getDoc(rentalRequestRef);

        if (rentalRequestDoc.exists()) {
          const rentalData = rentalRequestDoc.data();
          setRentalRequestDetails(rentalData);

          // Fetch listing details
          const listingId = rentalData.listingId;
          const listingRef = doc(db, "listings", listingId);
          const listingDoc = await getDoc(listingRef);
          if (listingDoc.exists()) {
            setListingDetails({
              ...listingDoc.data(),
              id: listingDoc.id,
            });
          } else {
            toast.error("Listing not found");
          }

          // Fetch reviewee details
          const userRef = doc(db, "users", revieweeUid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setRevieweeDetails(userDoc.data());
          } else {
            toast.error("User not found");
          }
        } else {
          toast.error("Rental request not found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to load data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rentalRequestId, revieweeUid]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <BeatLoader />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-center my-5 text-xl font-bold">
        Review Your Rental Experience
      </h1>

      {/* Listing Details */}
      <div className="w-full flex justify-center mb-10">
        <div className="flex flex-col w-full sm:w-2/3 md:w-1/2 lg:w-1/3 items-center rounded-md shadow-lg p-5">
          <h2 className="font-semibold my-3 text-lg text-gray-800 text-center">
            {listingDetails && listingDetails.title}
          </h2>
          <img
            src={
              listingDetails && listingDetails.imageURLs
                ? listingDetails.imageURLs[0]
                : ""
            }
            alt="Listing"
            className="w-full h-auto max-w-xs object-cover rounded-md mb-2"
          />
          <div className="flex flex-col text-gray-600 gap-2 mt-4">
            <p>
              <span className="font-bold">Duration:</span>{" "}
              {rentalRequestDetails && rentalRequestDetails.numberOfDays} days
            </p>
            <p>
              <span className="font-bold">From:</span>{" "}
              {rentalRequestDetails &&
                firestoreTimestampToDate(
                  rentalRequestDetails.startDate
                )?.toLocaleDateString()}
            </p>
            <p>
              <span className="font-bold">To:</span>{" "}
              {rentalRequestDetails &&
                firestoreTimestampToDate(
                  rentalRequestDetails.endDate
                )?.toLocaleDateString()}
            </p>
            <p className="text-center text-teal-500 font-semibold mt-2">
              {revieweeDetails && revieweeDetails.username}
            </p>
          </div>
        </div>
      </div>

      {/* Review Form */}
      <ReviewForm
        rentalRequestId={rentalRequestId}
        rentalRequestDetails={rentalRequestDetails}
        revieweeUid={revieweeUid}
        revieweeDetails={revieweeDetails}
        listingDetails={listingDetails}
      />
    </div>
  );
};

export default ReviewPage;
