import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const StarRating = ({ rating }) => {
  // Create an array of length 5 and fill it with nulls
  const stars = Array(5).fill(null);

  return (
    <div className="flex items-center gap-1">
      {stars.map((_, index) => (
        <FontAwesomeIcon
          key={index}
          icon={faStar}
          color={index < rating ? "gold" : "gray"}
        />
      ))}
    </div>
  );
};

export default StarRating;
