import React from "react";
import { BeatLoader } from "react-spinners";
import placeholderPictureURL from "../../assets/profilePicture.png";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import { useNavigate } from "react-router-dom";

const ListOfChats = ({
  onSelectChat,
  currentUser,
  selectedChatId,
  listOfChats,
  isLoading,
}) => {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <BeatLoader color="#14b8a6" />
      </div>
    );
  }

  const handleChatClick = async (chat) => {
    await onSelectChat(chat.id); // Ensure that onSelectChat is modified to handle marking messages as read
  };

  return (
    <div className="chat-list h-full overflow-y-auto">
      {listOfChats.length > 0 ? (
        listOfChats.map((chat) => {
          const hasUnreadMessages = chat.unreadBy?.includes(currentUser.uid);

          return (
            <div
              key={chat.id}
              className={`p-3 cursor-pointer border-b hover:bg-gray-100 transition-all ease-in-out duration-300 
              ${
                selectedChatId === chat.id
                  ? "bg-emerald-50 border-r-4 border-r-emerald-500"
                  : "bg-white"
              }`}
              onClick={() => handleChatClick(chat)}
            >
              {/* Status Badges */}
              <div className="flex justify-center mb-2">
                {chat.seen === false && chat.lenderUid === currentUser.uid ? (
                  <span className="px-3 py-1 text-xs font-semibold text-white bg-gradient-to-r from-teal-500 to-blue-500 rounded-full">
                    NEW REQUEST
                  </span>
                ) : chat.status === "availability-confirmed" &&
                  chat.renterUid === currentUser.uid ? (
                  <span className="px-3 py-1 text-xs font-semibold text-white bg-emerald-500 rounded-full">
                    CONFIRM RENTAL
                  </span>
                ) : hasUnreadMessages ? (
                  <span className="px-3 py-1 text-xs font-semibold text-white bg-red-500 rounded-full">
                    NEW MESSAGES
                  </span>
                ) : chat.status === "rental-confirmed" ? (
                  <span className="px-3 py-1 text-xs font-semibold text-white bg-teal-500 rounded-full">
                    CONFIRMED
                  </span>
                ) : null}
              </div>

              {/* Chat Information */}
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-semibold text-gray-800">
                    {chat.listingTitle}
                  </p>
                  <p className="text-xs text-gray-500">
                    {firestoreTimestampToDate(
                      chat.startDate
                    ).toLocaleDateString()}{" "}
                    -{" "}
                    {firestoreTimestampToDate(
                      chat.endDate
                    ).toLocaleDateString()}
                  </p>
                </div>
                <img
                  src={
                    chat.recipientDetails.profilePictureURL
                      ? chat.recipientDetails.profilePictureURL
                      : placeholderPictureURL
                  }
                  className="w-12 h-12 rounded-full object-cover ml-4 hover:cursor-pointer"
                  alt="Profile"
                  onClick={() => navigate(`/users/${chat.recipientDetails.id}`)}
                />
              </div>

              {/* Chat Recipient */}
              <p className="mt-2 text-sm text-gray-600">
                Chat with{" "}
                <span className="font-medium">
                  {chat.recipientDetails.username}
                </span>
              </p>
            </div>
          );
        })
      ) : (
        <p className="text-center text-gray-500 mt-5 font-bold">
          No chats found.
        </p>
      )}
    </div>
  );
};

export default ListOfChats;
