import React, { useState } from "react";
import Modal from "react-modal";
import VeriffButton from "../VeriffButton";
import { FaTimes } from "react-icons/fa";

const VerifyIDModal = ({ showVerifyIDModal, onClose, currentUser }) => {
  const customStyles = {
    content: {
      inset: "50% auto auto 50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      borderRadius: "8px",
      padding: "24px",
      maxWidth: "500px",
      width: "90%",
      border: "none",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <Modal
      isOpen={showVerifyIDModal}
      onRequestClose={onClose}
      contentLabel="Verify Your Identity"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="flex flex-col p-2">
        {/* Modal Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">
            Verify Your Identity
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
        </div>

        {/* Modal Content */}
        <div className="text-gray-700 mb-6">
          <p className="mb-4">
            To rent items on KitShare, please verify your identity through our
            trusted partner, Veriff. This helps maintain a safe and secure
            community.
          </p>
          <p className="mb-4">
            The verification process only takes 2 - 3 minutes.
          </p>
          <p>Once verified, you can start renting items immediately!</p>
        </div>

        {/* Modal Actions */}
        <div className="flex justify-center gap-4">
          <button onClick={onClose} className="btn-cancel px-5">
            Cancel
          </button>
          {/* <button
            onClick={handleVerification}
            className="btn-continue px-5"
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Verify Now"}
          </button> */}
          <VeriffButton currentUser={currentUser} />
        </div>
      </div>
    </Modal>
  );
};

export default VerifyIDModal;
