import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase.config";
import {
  getDoc,
  doc,
  collection,
  where,
  query,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import profilePicPlaceholder from "../assets/profilePicture.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faShieldAlt,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import UserProfileListingCard from "../components/UserProfile/UserProfileListingCard";
import UserProfileReviews from "../components/UserProfile/UserProfileReviews";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState("listings");
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingReviews, setIsFetchingReviews] = useState(false);
  const [userListings, setUserListings] = useState([]);
  const [userReviews, setUserReviews] = useState([]);
  const { userUid } = useParams();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    let userListingsVariable = [];
    const fetchUserListings = async () => {
      setIsLoading(true);
      const q = query(
        collection(db, "listings"),
        where("lenderUid", "==", userUid)
      );
      const qSnap = await getDocs(q);
      if (qSnap.empty) {
        console.log("empty");
      } else {
        qSnap.forEach((listing) => {
          userListingsVariable.push({ ...listing.data(), id: listing.id });
        });

        setUserListings(userListingsVariable);
        setIsLoading(false);
      }
    };

    fetchUserListings();
  }, [userUid]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      const userRef = doc(db, "users", userUid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        setUserDetails(docSnap.data());
      }
      setIsLoading(false);
    };

    fetchUserDetails();
  }, [userUid]);

  useEffect(() => {
    const fetchReviews = async () => {
      setIsFetchingReviews(true);
      let reviewsArray = [];

      try {
        const reviewsRef = collection(db, "users", userUid, "reviews");
        const q = query(reviewsRef, orderBy("createdAt", "desc"), limit(10));
        const querySnapshot = await getDocs(q);

        for (let document of querySnapshot.docs) {
          const reviewData = { id: document.id, ...document.data() };
          const reviewerRef = doc(db, "users", reviewData.reviewerUid);
          const reviewerSnap = await getDoc(reviewerRef);

          if (reviewerSnap.exists()) {
            const reviewerData = reviewerSnap.data();
            reviewsArray.push({
              ...reviewData,
              reviewerUsername: reviewerData.username,
              reviewerProfilePictureURL: reviewerData.profilePictureURL,
            });
          } else {
            reviewsArray.push(reviewData);
          }
        }

        setReviews(reviewsArray);
      } catch (error) {
        console.error("Error fetching reviews: ", error);
      } finally {
        setIsFetchingReviews(false);
      }
    };

    fetchReviews();
  }, [userUid]);

  return (
    <div className="flex justify-center flex-col align-center items-center mt-5 p-4">
      {/* Profile Picture */}
      <div className="relative rounded-full w-36 h-36 bg-gray-200 overflow-hidden mb-4">
        <img
          src={
            userDetails.profilePictureURL
              ? userDetails.profilePictureURL
              : profilePicPlaceholder
          }
          alt="ProfilePicture"
          className="object-cover w-full h-full text-center"
          onError={(e) => (e.target.src = profilePicPlaceholder)}
        />
      </div>

      {/* Username, general location, and rating overview */}
      <div className="text-center">
        <div className="flex justify-center items-center align-center gap-3">
          <p className="font-semibold text-gray-800">{userDetails.username}</p>
          <div className="flex gap-1 justify-center align-center items-center">
            {userDetails.averageReviewRating}
            {"  "}
            <span>
              {" "}
              <FontAwesomeIcon icon={faStar} color="gold" />
            </span>
            <span className="text-sm text-gray-600">
              ({userDetails.reviewCount})
            </span>
          </div>
        </div>
        <p className="text-sm text-gray-600 mt-2">
          {userDetails.formattedAddress || "Location not specified"}
        </p>
        {userDetails?.createdAt ? (
          <p className="text-sm mt-2 text-gray-600">
            Member since:{" "}
            {firestoreTimestampToDate(
              userDetails.createdAt
            ).toLocaleDateString()}
          </p>
        ) : (
          <p className="text-sm mt-2 text-gray-600">Member since: Unknown</p>
        )}

        {userDetails?.idVerified ? (
          <p className="py-3 my-3 border-2 rounded-md border-emerald-500 font-semibold text-gray-700">
            ID Verified{" "}
            <FontAwesomeIcon
              icon={faShieldAlt}
              className="text-emerald-600 text-lg"
            />
          </p>
        ) : (
          <p className="py-3 my-2 border-2 rounded-md border-red-600 text-gray-700">
            ID Unverified
            <FontAwesomeIcon
              icon={faWarning}
              className="text-red-600 text-lg"
            />
          </p>
        )}
      </div>

      {/* About */}
      <div className="w-full md:w-2/3 lg:w-1/2 mt-4">
        <label className="font-semibold border-b-2 border-gray-400 text-gray-700">
          About Me
        </label>
        <p className="bg-gray-100 p-3 rounded-md shadow-md whitespace-pre-line text-gray-800 mt-2">
          {userDetails.about || "No bio provided."}
        </p>
      </div>

      {/* Tabs for Listings and Reviews */}
      <div className="mt-6">
        {/* Buttons */}
        <div className="flex gap-3 justify-center mb-4">
          <button
            className={`px-8 py-3 rounded-md ${
              activeTab === "listings" ? "btn-continue" : "btn-secondary"
            }`}
            onClick={() => setActiveTab("listings")}
          >
            Listings
          </button>
          <button
            className={`px-8 py-3 rounded-md ${
              activeTab === "reviews" ? "btn-continue" : "btn-secondary"
            }`}
            onClick={() => setActiveTab("reviews")}
          >
            Reviews
          </button>
        </div>

        {/* Conditional Rendering */}
        {activeTab === "listings" ? (
          <div className="">
            <div className="mt-10 max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-5">
                {userListings.map((userListing, index) => (
                  <UserProfileListingCard
                    listingDetails={userListing}
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <UserProfileReviews
            userUid={userUid}
            reviews={reviews}
            isFetchingReviews={isFetchingReviews}
          />
        )}
      </div>
    </div>
  );
};

export default UserProfile;
