import React from "react";
import Footer from "../components/Footer";
import AlgoliaSearch from "../components/AlgoliaSearch";

import HowItWorks from "../components/HowItWorks";
import FAQ from "../components/FAQ";
import LatestListings from "../components/LatestListings";
import world from "../assets/world.png";
import BecomeLenderCTA from "../components/BecomeLenderCTA";

const Home = () => {
  return (
    <>
      <div className="relative h-screen bg-waves flex justify-center">
        {/* Container to position content */}
        <div className="absolute top-1/4 md:top-1/6 w-full flex flex-col md:flex-row items-center justify-center max-w-screen-lg px-10 md:px-0">
          {/* 2/3 of screen (desktop) */}
          <div className="flex flex-col gap-5 w-full md:w-2/3 items-center md:items-start text-center md:text-left">
            <p className="font-semibold text-gray-700 text-lg">
              Rent instead of buying
            </p>
            <p className="text-gray-700 text-4xl md:text-5xl font-semibold">
              Hire <span className="text-teal-500">anything</span> from people
              in your area
            </p>
            <div className="w-full mt-4">
              <AlgoliaSearch />
            </div>
          </div>
          {/* 1/3 of screen (desktop) */}
          <div className="hidden md:flex justify-center items-center w-1/3">
            <img src={world} alt="rent" className="w-full max-w-sm" />
          </div>
        </div>
      </div>

      <div className="flex justify-center py-2 bg-gray-100">
        <HowItWorks />
      </div>
      <div className="">
        <LatestListings />
      </div>
      <BecomeLenderCTA />

      <FAQ />
      <Footer />
    </>
  );
};

export default Home;
